import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Divider,
  CircularProgress,
  Skeleton,
  TextField,
  InputAdornment,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Add as AddIcon, 
  Delete as DeleteIcon,
  Search as SearchIcon,
  Save as SaveIcon,
  DesignServices as TemplateIcon,
} from '@mui/icons-material';
import { productsApi } from '../../services/productsApi';
import { Product, ProductType } from '../../types/products';
import { 
  InterfaceComponent, 
  ComponentType, 
  ComponentConfig, 
  InterfaceTemplate,
  InterfaceConfig
} from '../../types/interface-components';
import ComponentPicker from '../../components/ComponentPicker/ComponentPicker';
import Layout from '../../components/Layout/Layout';
import ComponentEditor from '../../components/ComponentEditor/ComponentEditor';
import Notification from '../../components/Notification/Notification';
import { useDebounce } from '../../hooks/useDebounce';
import InterfacePreview from '../../components/InterfacePreview/InterfacePreview';
import InterfaceTemplates from '../../components/InterfaceTemplates/InterfaceTemplates';
import { StrictMode } from 'react';
import { ComponentRenderer } from '../../components/InterfaceComponents/ComponentRenderer';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
}));

const ComponentPreview = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(1),
  cursor: 'grab',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '12px',
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  '&.Mui-selected': {
    backgroundColor: 'rgba(220, 251, 1, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(220, 251, 1, 0.15)',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(220, 251, 1, 0.05)',
  },
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DCFB01',
    },
  },
}));

const ComponentList = styled(Box)(({ theme }) => ({
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const DraggableComponent = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&:hover .delete-button': {
    opacity: 1,
  },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  zIndex: 1,
}));

const InterfaceBuilder: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [components, setComponents] = useState<InterfaceComponent[]>([]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const productsPerPage = 10;
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });
  const debouncedComponents = useDebounce(components, 1000);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
    setPage(1); // Сброс на первую страницу при поиске
  }, [searchTerm, products]);

  useEffect(() => {
    if (selectedProduct && debouncedComponents !== components) {
      handleSaveInterface();
    }
  }, [debouncedComponents]);

  const getCurrentPageProducts = () => {
    const startIndex = (page - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    return filteredProducts.slice(startIndex, endIndex);
  };

  const loadProducts = async () => {
    try {
      setLoading(true);
      const response = await productsApi.getAllProducts({});
      setProducts(response.data.products);
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = async (product: Product) => {
    setSelectedProduct(product);
    setLoadingConfig(true);
    try {
      await loadInterfaceConfig(product.id);
    } finally {
      setLoadingConfig(false);
    }
  };

  const loadInterfaceConfig = async (productId: string) => {
    try {
      const response = await productsApi.getInterfaceConfig(productId);
      setComponents(response.data.components || []);
      showNotification('Interface configuration loaded successfully', 'success');
    } catch (error) {
      console.error('Error loading interface config:', error);
      showNotification('Failed to load interface configuration', 'error');
      setComponents([]);
    }
  };

  const handleDragStart = (e: React.DragEvent, index: number) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent, dropIndex: number) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
    
    if (dragIndex === dropIndex) return;

    const items = Array.from(components);
    const [reorderedItem] = items.splice(dragIndex, 1);
    items.splice(dropIndex, 0, reorderedItem);

    // Обновляем порядок компонентов
    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index,
    }));

    setComponents(updatedItems);
    showNotification('Component order updated', 'success');
  };

  const getDefaultPropsForType = (type: ComponentType): ComponentConfig => {
    const baseProps = {
      id: `prop-${Date.now()}`,
      type,
    };

    switch (type) {
      case 'text-input':
        return {
          ...baseProps,
          type: 'text-input' as const,
          label: 'New Text Input',
          placeholder: 'Enter text...',
          validation: {
            required: false
          }
        } as ComponentConfig;

      case 'checkbox':
        return {
          ...baseProps,
          type: 'checkbox' as const,
          label: 'New Checkbox',
          defaultValue: false,
        } as ComponentConfig;

      case 'radio-group':
        return {
          ...baseProps,
          type: 'radio-group' as const,
          label: 'New Radio Group',
          options: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' }
          ],
          defaultValue: 'option1',
        } as ComponentConfig;

      case 'select':
        return {
          ...baseProps,
          type: 'select' as const,
          label: 'New Dropdown',
          options: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' }
          ],
          placeholder: 'Select an option',
        } as ComponentConfig;

      case 'slider':
        return {
          ...baseProps,
          type: 'slider' as const,
          label: 'New Slider',
          min: 0,
          max: 100,
          step: 1,
          defaultValue: 50,
        } as ComponentConfig;

      case 'grid':
        return {
          ...baseProps,
          type: 'grid' as const,
          spacing: 2,
          items: [],
        };

      case 'flex-container':
        return {
          ...baseProps,
          type: 'flex-container' as const,
          direction: 'row',
          items: [],
        };

      case 'tabs':
        return {
          ...baseProps,
          type: 'tabs' as const,
          tabs: [],
        };

      case 'accordion':
        return {
          ...baseProps,
          type: 'accordion' as const,
          sections: [],
        };

      case 'card':
        return {
          ...baseProps,
          type: 'card' as const,
          content: [],
        };

      default:
        return {
          ...baseProps,
          type: type as ComponentType,
        } as ComponentConfig;
    }
  };

  const handleAddComponent = (componentType: ComponentType) => {
    const defaultProps = getDefaultPropsForType(componentType);
    const newComponent: InterfaceComponent = {
      id: `component-${Date.now()}-${components.length}`,
      type: componentType,
      props: {
        ...defaultProps,
        label: `New ${componentType}`,
      } as ComponentConfig,
      order: components.length
    };
    setComponents([...components, newComponent]);
    showNotification('Component added successfully', 'success');
  };

  const handleSaveInterface = async () => {
    if (!selectedProduct) return;

    try {
      await productsApi.updateInterfaceConfig(selectedProduct.id, {
        components,
        version: '1.0',
        updatedAt: new Date().toISOString()
      });
      showNotification('Interface saved successfully', 'success');
    } catch (error) {
      console.error('Error saving interface:', error);
      showNotification('Failed to save interface', 'error');
    }
  };

  const showNotification = (message: string, severity: 'success' | 'error') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  const handleDeleteComponent = (index: number) => {
    const newComponents = components.filter((_, i) => i !== index);
    setComponents(newComponents.map((comp, i) => ({ ...comp, order: i })));
    showNotification('Component deleted successfully', 'success');
  };

  const handleSaveAsTemplate = async (name: string, description: string) => {
    if (!selectedProduct) return;

    try {
      await productsApi.createInterfaceTemplate({
        name,
        description,
        productType: selectedProduct.type,
        components,
        layout: {
          type: 'grid',
          columns: 1,
          gap: 16,
          padding: 24
        },
        theme: {
          primary: '#DCFB01',
          secondary: '#793EEE',
          background: '#121212',
          text: '#FFFFFF',
          border: 'rgba(255, 255, 255, 0.1)'
        }
      });
      showNotification('Template saved successfully', 'success');
    } catch (error) {
      console.error('Error saving template:', error);
      showNotification('Failed to save template', 'error');
    }
  };

  const handleApplyTemplate = async (template: Omit<InterfaceTemplate, 'createdBy'>) => {
    if (!selectedProduct) return;

    try {
      await productsApi.applyInterfaceTemplate(template.id, selectedProduct.id);
      await loadInterfaceConfig(selectedProduct.id);
      showNotification('Template applied successfully', 'success');
    } catch (error) {
      console.error('Error applying template:', error);
      showNotification('Failed to apply template', 'error');
    }
  };

  const handleComponentChange = (index: number, value: any) => {
    const newComponents = [...components];
    newComponents[index] = {
      ...newComponents[index],
      props: {
        ...newComponents[index].props,
        defaultValue: value
      }
    };
    setComponents(newComponents);
  };

  const renderPreviewComponent = (component: InterfaceComponent) => (
    <ComponentRenderer
      config={component.props as ComponentConfig}
      preview={true}
      onChange={() => {}}
    />
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3, color: '#DCFB01' }}>
        Constructor
      </Typography>

      <Grid container spacing={3}>
        {/* Products List */}
        <Grid item xs={12} md={3}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Products
            </Typography>
            
            <SearchField
              fullWidth
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(220, 251, 1, 0.7)' }} />
                  </InputAdornment>
                ),
              }}
            />

            {loading ? (
              [...Array(5)].map((_, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Skeleton 
                    variant="rectangular" 
                    height={48} 
                    sx={{ 
                      bgcolor: 'rgba(220, 251, 1, 0.1)',
                      borderRadius: '12px'
                    }} 
                  />
                </Box>
              ))
            ) : (
              <>
                <List sx={{ '& .MuiListItem-root': { mb: 1 } }}>
                  {getCurrentPageProducts().map((product) => (
                    <StyledListItem
                      key={product.id}
                      selected={selectedProduct?.id === product.id}
                      onClick={() => handleProductSelect(product)}
                      sx={{
                        transition: 'all 0.2s ease-in-out',
                        '&:last-child': {
                          mb: 0
                        }
                      }}
                    >
                      <ListItemText 
                        primary={product.name}
                        primaryTypographyProps={{
                          sx: {
                            color: selectedProduct?.id === product.id ? '#DCFB01' : 'inherit'
                          }
                        }}
                      />
                    </StyledListItem>
                  ))}
                </List>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    count={Math.ceil(filteredProducts.length / productsPerPage)}
                    page={page}
                    onChange={(_, newPage) => setPage(newPage)}
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: '#fff',
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(220, 251, 1, 0.2)',
                          color: '#DCFB01',
                          '&:hover': {
                            backgroundColor: 'rgba(220, 251, 1, 0.3)',
                          },
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(220, 251, 1, 0.1)',
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </StyledPaper>
        </Grid>

        {/* Interface Builder */}
        <Grid item xs={12} md={9}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {selectedProduct ? `Editor: ${selectedProduct.name}` : 'Select a product'}
            </Typography>

            {loadingConfig ? (
              <Box sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <Skeleton 
                    variant="rectangular" 
                    height={60} 
                    sx={{ 
                      bgcolor: 'rgba(220, 251, 1, 0.1)',
                      borderRadius: '12px'
                    }} 
                  />
                </Box>
                {[...Array(3)].map((_, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Skeleton 
                      variant="rectangular" 
                      height={80} 
                      sx={{ 
                        bgcolor: 'rgba(220, 251, 1, 0.05)',
                        borderRadius: '12px'
                      }} 
                    />
                  </Box>
                ))}
              </Box>
            ) : selectedProduct ? (
              <Box>
                <ComponentList>
                  {components.map((component, index) => (
                    <DraggableComponent
                      key={component.id}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <ComponentEditor
                          component={component}
                          onUpdate={(updatedComponent) => {
                            const newComponents = [...components];
                            newComponents[index] = updatedComponent;
                            setComponents(newComponents);
                          }}
                          onDelete={() => {
                            const newComponents = components.filter((_, i) => i !== index);
                            setComponents(newComponents);
                            showNotification('Component deleted successfully', 'success');
                          }}
                        />
                        <Box sx={{ 
                          p: 2, 
                          bgcolor: 'rgba(220, 251, 1, 0.03)',
                          borderRadius: '12px',
                          border: '1px solid rgba(220, 251, 1, 0.1)'
                        }}>
                          <Typography variant="caption" sx={{ color: 'rgba(220, 251, 1, 0.7)', mb: 1, display: 'block' }}>
                            Preview:
                          </Typography>
                          <ComponentRenderer
                            config={component.props as ComponentConfig}
                            onChange={(value) => handleComponentChange(index, value)}
                          />
                        </Box>
                      </Box>
                    </DraggableComponent>
                  ))}
                </ComponentList>

                <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setPickerOpen(true)}
                    sx={{
                      bgcolor: '#DCFB01',
                      color: '#000',
                      '&:hover': {
                        bgcolor: 'rgba(220, 251, 1, 0.8)',
                      }
                    }}
                  >
                    Add Component
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveInterface}
                    sx={{
                      borderColor: '#DCFB01',
                      color: '#DCFB01',
                      '&:hover': {
                        borderColor: 'rgba(220, 251, 1, 0.8)',
                        bgcolor: 'rgba(220, 251, 1, 0.1)',
                      }
                    }}
                  >
                    Save Interface
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<TemplateIcon />}
                    onClick={() => setTemplatesOpen(true)}
                    sx={{
                      borderColor: '#DCFB01',
                      color: '#DCFB01',
                      '&:hover': {
                        borderColor: 'rgba(220, 251, 1, 0.8)',
                        bgcolor: 'rgba(220, 251, 1, 0.1)',
                      }
                    }}
                  >
                    Templates
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setPreviewOpen(true)}
                    sx={{
                      borderColor: '#DCFB01',
                      color: '#DCFB01',
                      '&:hover': {
                        borderColor: 'rgba(220, 251, 1, 0.8)',
                        bgcolor: 'rgba(220, 251, 1, 0.1)',
                      }
                    }}
                  >
                    Preview
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box 
                sx={{ 
                  p: 4, 
                  textAlign: 'center',
                  color: 'rgba(255, 255, 255, 0.5)'
                }}
              >
                <Typography>
                  Select a product from the list to start editing its interface
                </Typography>
              </Box>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      <InterfaceTemplates
        open={templatesOpen}
        onClose={() => setTemplatesOpen(false)}
        onApplyTemplate={handleApplyTemplate}
        onSaveAsTemplate={handleSaveAsTemplate}
        currentConfig={{
          components,
          version: '1.0',
          updatedAt: new Date().toISOString()
        }}
        productType={selectedProduct?.type as ProductType}
      />

      <ComponentPicker
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        onSelect={handleAddComponent}
      />

      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={handleCloseNotification}
      />

      <InterfacePreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        components={components}
        productName={selectedProduct?.name || ''}
        renderComponent={renderPreviewComponent}
      />
    </Box>
  );
};

export default InterfaceBuilder; 