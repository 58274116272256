import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#DCFB01',
    },
    secondary: {
      main: '#793EEE',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
    h1: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: 'Inter Tight, sans-serif',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Manrope, sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Manrope, sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
          fontFamily: 'Manrope, sans-serif',
        },
      },
    },
  },
}); 