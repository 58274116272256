import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Switch,
  IconButton,
  Collapse,
  Button,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  DragIndicator as DragIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  TextInputConfig,
  NumberInputConfig,
  SelectConfig,
  RadioGroupConfig,
  ComponentConfig,
  InterfaceComponent,
  TabsConfig,
  AccordionConfig,
  CardConfig,
  GridConfig,
  FlexContainerConfig,
  DatePickerConfig,
  TimePickerConfig,
  FileUploadConfig,
  ImageUploadConfig,
  ChatInterfaceConfig,
  ComponentType,
  CheckboxConfig,
  SliderConfig
} from '../../types/interface-components';
import ComponentPicker from '../ComponentPicker/ComponentPicker';
import {
  DatePicker,
  TimePicker,
  LocalizationProvider 
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const EditorContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '12px',
  border: '1px solid rgba(220, 251, 1, 0.1)',
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
  }
});

const StyledSelect = styled(Select<string>)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  '&.Mui-disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.01)',
  }
}));

interface ComponentEditorProps {
  component: InterfaceComponent;
  onUpdate: (component: InterfaceComponent) => void;
  onDelete: () => void;
}

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  description?: string;
  icon?: string;
}

const ComponentEditor: React.FC<ComponentEditorProps> = ({
  component,
  onUpdate,
  onDelete,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [editingItemIndex, setEditingItemIndex] = useState<number | null>(null);

  const handleBasicPropChange = (prop: string, value: any) => {
    onUpdate({
      ...component,
      props: {
        ...component.props,
        [prop]: value,
      },
    });
  };

  const handleComponentUpdate = (updatedComponent: InterfaceComponent) => {
    onUpdate({
      ...updatedComponent,
      id: component.id,
      order: component.order,
      parentId: component.parentId,
    } as InterfaceComponent);
  };

  const handleContentUpdate = (
    type: 'tabs' | 'sections',
    parentIndex: number,
    contentIndex: number,
    updatedComponent: InterfaceComponent
  ) => {
    if (type === 'tabs') {
      const config = component.props as TabsConfig;
      const newTabs = [...config.tabs];
      newTabs[parentIndex].content[contentIndex] = updatedComponent;
      handleBasicPropChange('tabs', newTabs);
    } else {
      const config = component.props as AccordionConfig;
      const newSections = [...config.sections];
      newSections[parentIndex].content[contentIndex] = updatedComponent;
      handleBasicPropChange('sections', newSections);
    }
  };

  const renderTabsEditor = (config: TabsConfig) => {
    return (
      <Box>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Tabs Configuration</Typography>
        
        {/* Основные настройки табов */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Orientation</InputLabel>
              <Select
                value={config.orientation || 'horizontal'}
                onChange={(e) => handleBasicPropChange('orientation', e.target.value)}
              >
                <MenuItem value="horizontal">Horizontal</MenuItem>
                <MenuItem value="vertical">Vertical</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Variant</InputLabel>
              <Select
                value={config.variant || 'standard'}
                onChange={(e) => handleBasicPropChange('variant', e.target.value)}
              >
                <MenuItem value="standard">Standard</MenuItem>
                <MenuItem value="fullWidth">Full Width</MenuItem>
                <MenuItem value="scrollable">Scrollable</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Управление табами */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Tabs</Typography>
          {config.tabs?.map((tab: TabsConfig['tabs'][0], index: number) => (
            <Box
              key={tab.id}
              sx={{
                p: 2,
                mb: 1,
                border: '1px solid rgba(220, 251, 1, 0.1)',
                borderRadius: '8px',
                backgroundColor: selectedTabIndex === index ? 'rgba(220, 251, 1, 0.05)' : 'transparent',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <DragIcon sx={{ color: 'rgba(220, 251, 1, 0.5)', mr: 1 }} />
                <StyledTextField
                  size="small"
                  value={tab.label}
                  onChange={(e) => {
                    const newTabs = [...config.tabs];
                    newTabs[index] = { ...tab, label: e.target.value };
                    handleBasicPropChange('tabs', newTabs);
                  }}
                  sx={{ flex: 1 }}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    const newTabs = config.tabs.filter((_: unknown, i: number) => i !== index);
                    handleBasicPropChange('tabs', newTabs);
                  }}
                  sx={{ color: '#DCFB01', ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>

              {/* Контент таба */}
              <Box sx={{ pl: 4 }}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setSelectedTabIndex(index);
                    setPickerOpen(true);
                  }}
                  sx={{
                    color: '#DCFB01',
                    '&:hover': {
                      backgroundColor: 'rgba(220, 251, 1, 0.1)',
                    }
                  }}
                >
                  Add Component
                </Button>
                {tab.content?.map((contentComponent, contentIndex) => (
                  <Box key={contentComponent.id} sx={{ mt: 1 }}>
                    <ComponentEditor
                      component={contentComponent}
                      onUpdate={(updatedComponent) => {
                        handleContentUpdate('tabs', index, contentIndex, updatedComponent);
                      }}
                      onDelete={() => {
                        const newTabs = [...config.tabs];
                        newTabs[index].content = newTabs[index].content.filter(
                          (_, i) => i !== contentIndex
                        );
                        handleBasicPropChange('tabs', newTabs);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              const newTab = {
                id: `tab-${Date.now()}`,
                label: `New Tab ${(config.tabs?.length || 0) + 1}`,
                content: [],
              };
              handleBasicPropChange('tabs', [...(config.tabs || []), newTab]);
            }}
            sx={{
              color: '#DCFB01',
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.1)',
              }
            }}
          >
            Add Tab
          </Button>
        </Box>
      </Box>
    );
  };

  const renderAccordionEditor = (config: AccordionConfig) => {
    return (
      <Box>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Accordion Configuration</Typography>
        
        <FormControlLabel
          control={
            <Switch
              checked={config.allowMultiple || false}
              onChange={(e) => handleBasicPropChange('allowMultiple', e.target.checked)}
            />
          }
          label="Allow Multiple Sections"
        />

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Sections</Typography>
          {config.sections?.map((section: AccordionConfig['sections'][0], index: number) => (
            <Box
              key={section.id}
              sx={{
                p: 2,
                mb: 1,
                border: '1px solid rgba(220, 251, 1, 0.1)',
                borderRadius: '8px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <DragIcon sx={{ color: 'rgba(220, 251, 1, 0.5)', mr: 1 }} />
                <StyledTextField
                  size="small"
                  value={section.title}
                  onChange={(e) => {
                    const newSections = [...config.sections];
                    newSections[index] = { ...section, title: e.target.value };
                    handleBasicPropChange('sections', newSections);
                  }}
                  sx={{ flex: 1, mr: 1 }}
                  placeholder="Section Title"
                />
                <StyledTextField
                  size="small"
                  value={section.subtitle || ''}
                  onChange={(e) => {
                    const newSections = [...config.sections];
                    newSections[index] = { ...section, subtitle: e.target.value };
                    handleBasicPropChange('sections', newSections);
                  }}
                  sx={{ flex: 1 }}
                  placeholder="Subtitle (optional)"
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    const newSections = config.sections.filter((_: unknown, i: number) => i !== index);
                    handleBasicPropChange('sections', newSections);
                  }}
                  sx={{ color: '#DCFB01', ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>

              <Box sx={{ pl: 4 }}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setSelectedSectionIndex(index);
                    setPickerOpen(true);
                  }}
                  sx={{
                    color: '#DCFB01',
                    '&:hover': {
                      backgroundColor: 'rgba(220, 251, 1, 0.1)',
                    }
                  }}
                >
                  Add Component
                </Button>
                {section.content?.map((contentComponent, contentIndex) => (
                  <Box key={contentComponent.id} sx={{ mt: 1 }}>
                    <ComponentEditor
                      component={contentComponent}
                      onUpdate={(updatedComponent) => {
                        handleContentUpdate('sections', index, contentIndex, updatedComponent);
                      }}
                      onDelete={() => {
                        const newSections = [...config.sections];
                        newSections[index].content = newSections[index].content.filter(
                          (_, i) => i !== contentIndex
                        );
                        handleBasicPropChange('sections', newSections);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              const newSection = {
                id: `section-${Date.now()}`,
                title: `New Section ${(config.sections?.length || 0) + 1}`,
                content: [],
              };
              handleBasicPropChange('sections', [...(config.sections || []), newSection]);
            }}
            sx={{
              color: '#DCFB01',
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.1)',
              }
            }}
          >
            Add Section
          </Button>
        </Box>
      </Box>
    );
  };

  const renderCardEditor = (config: CardConfig) => {
    return (
      <Box>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Card Configuration</Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Title"
              value={config.title || ''}
              onChange={(e) => handleBasicPropChange('title', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Subtitle"
              value={config.subtitle || ''}
              onChange={(e) => handleBasicPropChange('subtitle', e.target.value)}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Content</Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setPickerOpen(true)}
            sx={{
              color: '#DCFB01',
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.1)',
              }
            }}
          >
            Add Component
          </Button>
          {config.content?.map((contentComponent, index) => (
            <Box key={contentComponent.id} sx={{ mt: 1 }}>
              <ComponentEditor
                component={contentComponent}
                onUpdate={(updatedComponent) => {
                  const newContent = [...config.content];
                  newContent[index] = updatedComponent;
                  handleBasicPropChange('content', newContent);
                }}
                onDelete={() => {
                  const newContent = config.content.filter((_, i) => i !== index);
                  handleBasicPropChange('content', newContent);
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const handleAddComponent = (componentType: ComponentType) => {
    const getDefaultProps = (type: ComponentType): ComponentConfig => {
      const baseProps = {
        id: `prop-${Date.now()}`
      };

      switch (type) {
        case 'text-input':
          return {
            ...baseProps,
            type: 'text-input' as const,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
            placeholder: 'Enter text...',
            validation: {
              required: false
            }
          } as TextInputConfig;

        case 'number-input':
          return {
            ...baseProps,
            type: 'number-input' as const,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
            min: 0,
            max: 100,
            step: 1,
            validation: {
              required: false
            }
          } as NumberInputConfig;

        case 'select':
          return {
            ...baseProps,
            type: 'select' as const,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
            options: [
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' }
            ]
          } as SelectConfig;

        case 'checkbox':
          return {
            ...baseProps,
            type: 'checkbox' as const,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
          } as CheckboxConfig;

        case 'radio-group':
          return {
            ...baseProps,
            type: 'radio-group' as const,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
            options: [
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' }
            ]
          } as RadioGroupConfig;

        case 'slider':
          return {
            ...baseProps,
            type: 'slider' as const,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
            min: 0,
            max: 100,
            step: 1,
            defaultValue: 50
          } as SliderConfig;

        default:
          return {
            ...baseProps,
            type: type as ComponentType,
            label: `Grid Item ${(component.props as GridConfig).items.length + 1}`,
          } as ComponentConfig;
      }
    };

    if (component.type === 'grid') {
      const gridConfig = component.props as GridConfig;
      const newComponent: InterfaceComponent = {
        id: `content-${Date.now()}`,
        type: componentType,
        props: getDefaultProps(componentType),
        order: (gridConfig.items || []).length
      };

      const newItem = {
        id: `grid-item-${Date.now()}`,
        content: newComponent,
        gridProps: {
          xs: 12,
          sm: 6,
          md: 4
        }
      };

      handleBasicPropChange('items', [...(gridConfig.items || []), newItem]);
    }
    setPickerOpen(false);
  };

  const renderGridEditor = (config: GridConfig) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ color: '#DCFB01' }}>
          Grid Configuration
        </Typography>

        {/* Основные настройки сетки */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              type="number"
              label="Spacing"
              value={config.spacing || 2}
              onChange={(e) => handleBasicPropChange('spacing', Number(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Justify Content</InputLabel>
              <StyledSelect
                value={config.justifyContent || 'flex-start'}
                onChange={(e: SelectChangeEvent<unknown>) => 
                  handleBasicPropChange('justifyContent', e.target.value as string)
                }
                label="Justify Content"
              >
                <MenuItem value="flex-start">Start</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="flex-end">End</MenuItem>
                <MenuItem value="space-between">Space Between</MenuItem>
                <MenuItem value="space-around">Space Around</MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Align Items</InputLabel>
              <StyledSelect
                value={config.alignItems || 'stretch'}
                onChange={(e: SelectChangeEvent<unknown>) => 
                  handleBasicPropChange('alignItems', e.target.value as string)
                }
                label="Align Items"
              >
                <MenuItem value="flex-start">Start</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="flex-end">End</MenuItem>
                <MenuItem value="stretch">Stretch</MenuItem>
                <MenuItem value="baseline">Baseline</MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>

        {/* Управление элементами сетки */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="subtitle2" sx={{ color: '#DCFB01' }}>
              Grid Items
            </Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setPickerOpen(true)}
              sx={{
                color: '#DCFB01',
                '&:hover': {
                  backgroundColor: 'rgba(220, 251, 1, 0.1)',
                }
              }}
            >
              Add Component
            </Button>
          </Box>

          {(config.items || []).map((item, index) => (
            <Box
              key={item.id}
              sx={{
                p: 2,
                mb: 2,
                border: '1px solid rgba(220, 251, 1, 0.1)',
                borderRadius: '8px',
                backgroundColor: 'rgba(220, 251, 1, 0.02)',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="subtitle2">
                  Grid Item {index + 1}
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => setEditingItemIndex(index)}
                    sx={{ color: '#DCFB01', mr: 1 }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      const newItems = config.items.filter((_, i) => i !== index);
                      handleBasicPropChange('items', newItems);
                    }}
                    sx={{ color: '#DCFB01' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>

              {/* Настройки размеров для разных брейкпоинтов */}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} sm={4}>
                  <StyledTextField
                    fullWidth
                    type="number"
                    label="XS (width)"
                    value={item.gridProps?.xs || 12}
                    onChange={(e) => {
                      const newItems = [...config.items];
                      newItems[index] = {
                        ...item,
                        gridProps: {
                          ...item.gridProps,
                          xs: Number(e.target.value)
                        }
                      };
                      handleBasicPropChange('items', newItems);
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <StyledTextField
                    fullWidth
                    type="number"
                    label="SM (width)"
                    value={item.gridProps?.sm || ''}
                    onChange={(e) => {
                      const newItems = [...config.items];
                      newItems[index] = {
                        ...item,
                        gridProps: {
                          ...item.gridProps,
                          sm: Number(e.target.value)
                        }
                      };
                      handleBasicPropChange('items', newItems);
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <StyledTextField
                    fullWidth
                    type="number"
                    label="MD (width)"
                    value={item.gridProps?.md || ''}
                    onChange={(e) => {
                      const newItems = [...config.items];
                      newItems[index] = {
                        ...item,
                        gridProps: {
                          ...item.gridProps,
                          md: Number(e.target.value)
                        }
                      };
                      handleBasicPropChange('items', newItems);
                    }}
                  />
                </Grid>
              </Grid>

              {/* Вложенный компонент */}
              <Box sx={{ mt: 2 }}>
                <ComponentEditor
                  component={item.content}
                  onUpdate={(updatedComponent) => {
                    const newItems = [...config.items];
                    newItems[index] = {
                      ...item,
                      content: updatedComponent
                    };
                    handleBasicPropChange('items', newItems);
                  }}
                  onDelete={() => {
                    const newItems = config.items.filter((_, i) => i !== index);
                    handleBasicPropChange('items', newItems);
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>

        {/* омпонент выбора типа */}
        <ComponentPicker
          open={pickerOpen}
          onClose={() => setPickerOpen(false)}
          onSelect={handleAddComponent}
        />
      </Box>
    );
  };

  const renderFlexContainerEditor = (config: FlexContainerConfig) => {
    return (
      <Box>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Flex Container Configuration</Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Direction</InputLabel>
              <Select
                value={config.direction || 'row'}
                onChange={(e) => handleBasicPropChange('direction', e.target.value)}
              >
                <MenuItem value="row">Row</MenuItem>
                <MenuItem value="row-reverse">Row Reverse</MenuItem>
                <MenuItem value="column">Column</MenuItem>
                <MenuItem value="column-reverse">Column Reverse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Wrap</InputLabel>
              <Select
                value={config.wrap || 'nowrap'}
                onChange={(e) => handleBasicPropChange('wrap', e.target.value)}
              >
                <MenuItem value="nowrap">No Wrap</MenuItem>
                <MenuItem value="wrap">Wrap</MenuItem>
                <MenuItem value="wrap-reverse">Wrap Reverse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Flex Items</Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setPickerOpen(true)}
            sx={{
              color: '#DCFB01',
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.1)',
              }
            }}
          >
            Add Flex Item
          </Button>
          {config.items?.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                mt: 1,
                p: 2,
                border: '1px solid rgba(220, 251, 1, 0.1)',
                borderRadius: '8px',
              }}
            >
              <ComponentEditor
                component={item.content}
                onUpdate={(updatedComponent) => {
                  const newItems = [...config.items];
                  newItems[index] = { ...item, content: updatedComponent };
                  handleBasicPropChange('items', newItems);
                }}
                onDelete={() => {
                  const newItems = config.items.filter((_, i) => i !== index);
                  handleBasicPropChange('items', newItems);
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const renderAdvancedInputEditor = () => {
    const props = component.props;

    switch (component.type) {
      case 'date-picker': {
        const dateConfig = props as DatePickerConfig;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <StyledTextField
              fullWidth
              label="Label"
              value={dateConfig.label || ''}
              onChange={(e) => handleBasicPropChange('label', e.target.value)}
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={dateConfig.disablePast || false}
                  onChange={(e) => handleBasicPropChange('disablePast', e.target.checked)}
                />
              }
              label="Disable Past Dates"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={dateConfig.disableFuture || false}
                  onChange={(e) => handleBasicPropChange('disableFuture', e.target.checked)}
                />
              }
              label="Disable Future Dates"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={dateConfig.clearable || false}
                  onChange={(e) => handleBasicPropChange('clearable', e.target.checked)}
                />
              }
              label="Allow Clear"
            />
          </Box>
        );
      }

      case 'time-picker': {
        const timeConfig = props as TimePickerConfig;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <StyledTextField
              fullWidth
              label="Label"
              value={timeConfig.label || ''}
              onChange={(e) => handleBasicPropChange('label', e.target.value)}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={timeConfig.ampm || false}
                  onChange={(e) => handleBasicPropChange('ampm', e.target.checked)}
                />
              }
              label="Use 12-hour Format"
            />

            <StyledTextField
              type="number"
              label="Step (minutes)"
              value={timeConfig.step || 1}
              onChange={(e) => handleBasicPropChange('step', parseInt(e.target.value))}
            />
          </Box>
        );
      }

      case 'file-upload': {
        const fileConfig = props as FileUploadConfig;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <StyledTextField
              fullWidth
              label="Label"
              value={fileConfig.label || ''}
              onChange={(e) => handleBasicPropChange('label', e.target.value)}
            />

            <StyledTextField
              fullWidth
              label="Drop Zone Text"
              value={fileConfig.dropzoneText || ''}
              onChange={(e) => handleBasicPropChange('dropzoneText', e.target.value)}
            />

            <StyledTextField
              type="number"
              label="Max File Size (MB)"
              value={fileConfig.maxSize || ''}
              onChange={(e) => handleBasicPropChange('maxSize', parseInt(e.target.value))}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={fileConfig.multiple || false}
                  onChange={(e) => handleBasicPropChange('multiple', e.target.checked)}
                />
              }
              label="Allow Multiple Files"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={fileConfig.preview || false}
                  onChange={(e) => handleBasicPropChange('preview', e.target.checked)}
                />
              }
              label="Show Preview"
            />

            {fileConfig.preview && (
              <Box sx={{ ml: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Preview Settings
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={fileConfig.previewConfig?.showFileName || false}
                      onChange={(e) => handleBasicPropChange('previewConfig', {
                        ...fileConfig.previewConfig,
                        showFileName: e.target.checked
                      })}
                    />
                  }
                  label="Show File Name"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={fileConfig.previewConfig?.showFileSize || false}
                      onChange={(e) => handleBasicPropChange('previewConfig', {
                        ...fileConfig.previewConfig,
                        showFileSize: e.target.checked
                      })}
                    />
                  }
                  label="Show File Size"
                />
              </Box>
            )}
          </Box>
        );
      }

      case 'image-upload': {
        const imageConfig = props as ImageUploadConfig;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <StyledTextField
              fullWidth
              label="Label"
              value={imageConfig.label || ''}
              onChange={(e) => handleBasicPropChange('label', e.target.value)}
            />

            <StyledTextField
              fullWidth
              label="Drop Zone Text"
              value={imageConfig.dropzoneText || ''}
              onChange={(e) => handleBasicPropChange('dropzoneText', e.target.value)}
            />

            <StyledTextField
              type="number"
              label="Max File Size (MB)"
              value={imageConfig.maxSize || ''}
              onChange={(e) => handleBasicPropChange('maxSize', parseInt(e.target.value))}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={imageConfig.multiple || false}
                  onChange={(e) => handleBasicPropChange('multiple', e.target.checked)}
                />
              }
              label="Allow Multiple Images"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={imageConfig.cropEnabled || false}
                  onChange={(e) => handleBasicPropChange('cropEnabled', e.target.checked)}
                />
              }
              label="Enable Image Cropping"
            />

            {imageConfig.cropEnabled && (
              <Box sx={{ ml: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Crop Settings
                </Typography>
                <StyledTextField
                  type="number"
                  label="Aspect Ratio"
                  value={imageConfig.cropConfig?.aspectRatio || ''}
                  onChange={(e) => handleBasicPropChange('cropConfig', {
                    ...imageConfig.cropConfig,
                    aspectRatio: parseFloat(e.target.value)
                  })}
                  helperText="Leave empty for free aspect ratio"
                />
              </Box>
            )}
          </Box>
        );
      }

      case 'chat-interface': {
        const chatConfig = props as ChatInterfaceConfig;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Основные настройки */}
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ color: '#DCFB01' }}>
                Basic Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="Bot Name"
                    value={chatConfig.botName || ''}
                    onChange={(e) => handleBasicPropChange('botName', e.target.value)}
                    placeholder="AI Assistant"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="User Label"
                    value={chatConfig.userNameLabel || ''}
                    onChange={(e) => handleBasicPropChange('userNameLabel', e.target.value)}
                    placeholder="You"
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Input Placeholder"
                    value={chatConfig.placeholder || ''}
                    onChange={(e) => handleBasicPropChange('placeholder', e.target.value)}
                    placeholder="Type your message..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Welcome Message"
                    value={chatConfig.welcomeMessage || ''}
                    onChange={(e) => handleBasicPropChange('welcomeMessage', e.target.value)}
                    placeholder="Hello! How can I help you today?"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Настройки отображения */}
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ color: '#DCFB01' }}>
                Display Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.showAvatar || false}
                        onChange={(e) => handleBasicPropChange('showAvatar', e.target.checked)}
                      />
                    }
                    label="Show Avatars"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.showTimestamp || false}
                        onChange={(e) => handleBasicPropChange('showTimestamp', e.target.checked)}
                      />
                    }
                    label="Show Timestamps"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.showTypingIndicator || false}
                        onChange={(e) => handleBasicPropChange('showTypingIndicator', e.target.checked)}
                      />
                    }
                    label="Show Typing Indicator"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Настройки функций */}
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ color: '#DCFB01' }}>
                Features
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.features?.fileAttachments || false}
                        onChange={(e) => handleBasicPropChange('features', {
                          ...chatConfig.features,
                          fileAttachments: e.target.checked
                        })}
                      />
                    }
                    label="File Attachments"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.features?.emoji || false}
                        onChange={(e) => handleBasicPropChange('features', {
                          ...chatConfig.features,
                          emoji: e.target.checked
                        })}
                      />
                    }
                    label="Emoji Support"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.features?.reactions || false}
                        onChange={(e) => handleBasicPropChange('features', {
                          ...chatConfig.features,
                          reactions: e.target.checked
                        })}
                      />
                    }
                    label="Message Reactions"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.features?.threadReplies || false}
                        onChange={(e) => handleBasicPropChange('features', {
                          ...chatConfig.features,
                          threadReplies: e.target.checked
                        })}
                      />
                    }
                    label="Thread Replies"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.features?.messageEditing || false}
                        onChange={(e) => handleBasicPropChange('features', {
                          ...chatConfig.features,
                          messageEditing: e.target.checked
                        })}
                      />
                    }
                    label="Message Editing"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatConfig.features?.messageDeletion || false}
                        onChange={(e) => handleBasicPropChange('features', {
                          ...chatConfig.features,
                          messageDeletion: e.target.checked
                        })}
                      />
                    }
                    label="Message Deletion"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* AI настройки */}
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ color: '#DCFB01' }}>
                AI Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Model"
                    value={chatConfig.ai?.model || ''}
                    onChange={(e) => handleBasicPropChange('ai', {
                      ...chatConfig.ai,
                      model: e.target.value
                    })}
                    placeholder="gpt-4"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    type="number"
                    label="Temperature"
                    value={chatConfig.ai?.temperature || 0.7}
                    onChange={(e) => handleBasicPropChange('ai', {
                      ...chatConfig.ai,
                      temperature: parseFloat(e.target.value)
                    })}
                    inputProps={{ min: 0, max: 1, step: 0.1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    type="number"
                    label="Max Tokens"
                    value={chatConfig.ai?.maxTokens || 2048}
                    onChange={(e) => handleBasicPropChange('ai', {
                      ...chatConfig.ai,
                      maxTokens: parseInt(e.target.value)
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={4}
                    label="System Prompt"
                    value={chatConfig.ai?.systemPrompt || ''}
                    onChange={(e) => handleBasicPropChange('ai', {
                      ...chatConfig.ai,
                      systemPrompt: e.target.value
                    })}
                    placeholder="You are a helpful AI assistant..."
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      }

      default:
        return null;
    }
  };

  const renderBasicInputEditor = () => {
    const props = component.props;
    const isTextInput = component.type === 'text-input';
    const isNumberInput = component.type === 'number-input';
    const isSelectOrRadio = component.type === 'select' || component.type === 'radio-group';

    const handleOptionChange = (index: number, field: keyof Option, value: string) => {
      if (isSelectOrRadio) {
        const config = props as SelectConfig | RadioGroupConfig;
        const newOptions = [...config.options];
        newOptions[index] = { ...newOptions[index], [field]: value };
        handleBasicPropChange('options', newOptions);
      }
    };

    const handleRemoveOption = (index: number) => {
      if (isSelectOrRadio) {
        const config = props as SelectConfig | RadioGroupConfig;
        const newOptions = [...config.options].filter((_: Option, i: number) => i !== index);
        handleBasicPropChange('options', newOptions);
      }
    };

    const handleAddOption = () => {
      if (isSelectOrRadio) {
        const config = props as SelectConfig | RadioGroupConfig;
        const newOptions = [...(config.options || []), { value: '', label: '' } as Option];
        handleBasicPropChange('options', newOptions);
      }
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTextField
          fullWidth
          label="Label"
          value={props.label || ''}
          onChange={(e) => handleBasicPropChange('label', e.target.value)}
        />

        <StyledTextField
          fullWidth
          label="Placeholder"
          value={props.placeholder || ''}
          onChange={(e) => handleBasicPropChange('placeholder', e.target.value)}
        />

        {isTextInput && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={(props as TextInputConfig).multiline || false}
                  onChange={(e) => handleBasicPropChange('multiline', e.target.checked)}
                />
              }
              label="Multiline"
            />
            {(props as TextInputConfig).multiline && (
              <StyledTextField
                fullWidth
                type="number"
                label="Rows"
                value={(props as TextInputConfig).rows || 3}
                onChange={(e) => handleBasicPropChange('rows', parseInt(e.target.value))}
              />
            )}
          </>
        )}

        {isNumberInput && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <StyledTextField
                fullWidth
                type="number"
                label="Min"
                value={(props as NumberInputConfig).min || ''}
                onChange={(e) => handleBasicPropChange('min', parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                fullWidth
                type="number"
                label="Max"
                value={(props as NumberInputConfig).max || ''}
                onChange={(e) => handleBasicPropChange('max', parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                fullWidth
                type="number"
                label="Step"
                value={(props as NumberInputConfig).step || ''}
                onChange={(e) => handleBasicPropChange('step', parseFloat(e.target.value))}
              />
            </Grid>
          </Grid>
        )}

        {isSelectOrRadio && (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Options
            </Typography>
            {((props as SelectConfig | RadioGroupConfig).options || []).map((option: Option, index: number) => (
              <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                <StyledTextField
                  label="Value"
                  value={option.value}
                  onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                />
                <StyledTextField
                  label="Label"
                  value={option.label}
                  onChange={(e) => handleOptionChange(index, 'label', e.target.value)}
                />
                <IconButton 
                  onClick={() => handleRemoveOption(index)}
                  sx={{ color: '#DCFB01' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddOption}
              sx={{
                color: '#DCFB01',
                '&:hover': {
                  backgroundColor: 'rgba(220, 251, 1, 0.1)',
                }
              }}
            >
              Add Option
            </Button>
          </Box>
        )}

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Validation
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={props.validation?.required || false}
                onChange={(e) => handleBasicPropChange('validation', {
                  ...props.validation,
                  required: e.target.checked
                })}
              />
            }
            label="Required"
          />
        </Box>
      </Box>
    );
  };

  return (
    <EditorContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1" sx={{ color: '#DCFB01' }}>
          {component.type.split('-').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')}
        </Typography>
        <Box>
          <IconButton
            onClick={() => setExpanded(!expanded)}
            sx={{ color: '#DCFB01' }}
          >
            <ExpandMoreIcon sx={{
              transform: expanded ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.2s'
            }} />
          </IconButton>
          <IconButton
            onClick={onDelete}
            sx={{ color: '#DCFB01' }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <Collapse in={expanded}>
        {component.type === 'date-picker' || 
         component.type === 'time-picker' || 
         component.type === 'file-upload' || 
         component.type === 'image-upload' || 
         component.type === 'chat-interface' 
          ? renderAdvancedInputEditor()
          : renderBasicInputEditor()}
      </Collapse>

      <ComponentPicker
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        onSelect={(type) => {
          // Добавлеие нового компнента в выбранный таб или секцию
          // ... логика добавления компонента ...
        }}
      />
    </EditorContainer>
  );
};

export default ComponentEditor; 