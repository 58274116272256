import axios from 'axios';

const api = axios.create({
  baseURL: '/api'
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('adminToken');
  
  console.log('🚀 API Request:', {
    url: config.url,
    method: config.method?.toUpperCase(),
    hasToken: !!token,
    tokenPreview: token ? `${token.substring(0, 10)}...` : 'no token'
  });

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    console.log('🔑 Added token to request headers');
  } else {
    console.warn('⚠️ No token found for request');
  }

  return config;
}, (error) => {
  console.error('❌ Request interceptor error:', error);
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => {
    console.log('✅ API Response:', {
      url: response.config.url,
      status: response.status,
      statusText: response.statusText,
      dataPreview: JSON.stringify(response.data).substring(0, 100) + '...'
    });
    return response;
  },
  (error) => {
    console.error('❌ API Error:', {
      url: error.config?.url,
      status: error.response?.status,
      statusText: error.response?.statusText,
      error: error.message,
      response: error.response?.data
    });

    if (error.response?.status === 401) {
      console.warn('🚫 Unauthorized - clearing token and redirecting to login');
      localStorage.removeItem('adminToken');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api; 