import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tabs,
  Tab,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessRequestsTable from '../../components/AccessRequestsTable/AccessRequestsTable';
import api from '../../services/api';

// Обновляем стили для табов
const StyledTabs = styled(Tabs)({
  marginBottom: '24px',
  '& .MuiTab-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '12px',
    minHeight: '40px',
    padding: '8px 24px',
    margin: '0 8px',
    transition: 'all 0.2s ease',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&.Mui-selected': {
      color: '#000',
      backgroundColor: '#DCFB01',
      '&:hover': {
        backgroundColor: 'rgba(220, 251, 1, 0.8)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(220, 251, 1, 0.1)',
      color: '#DCFB01',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

// Стилизованная кнопка таба
const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.875rem',
});

// Стилизованный Dialog
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
  },
  '& .MuiDialogTitle-root': {
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
  },
}));

// Стилизованное текстовое поле
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(220, 251, 1, 0.7)',
    '&.Mui-focused': {
      color: '#DCFB01',
    },
  },
}));

type AssignOption = 'auto' | 'manual';

const AccessRequests: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [currentRequestId, setCurrentRequestId] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<AssignOption>('auto');
  const [manualTeamId, setManualTeamId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const refreshTable = () => {
    console.log('🔄 Refreshing access requests table');
    setRefreshKey(prev => prev + 1);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleAssignTeam = (requestId: string) => {
    console.log('Opening dialog for request:', requestId);
    setCurrentRequestId(requestId);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedOption('auto');
    setManualTeamId('');
    setCurrentRequestId(null);
  };

  const handleConfirm = async () => {
    if (!currentRequestId) return;

    try {
      setIsLoading(true);
      if (selectedOption === 'auto') {
        await handleGenerateTeam();
      } else {
        await handleManualTeamAssign();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateTeam = async () => {
    if (!currentRequestId) return;

    try {
      setIsLoading(true);
      console.log('🔄 Generating team for request:', currentRequestId);
      
      const response = await api.post(`/admin/access-requests/${currentRequestId}/generate-team`, {
        adminId: localStorage.getItem('userId')
      });
      
      console.log('✅ Team generated:', response.data.team);
      
      handleCloseDialog();
      refreshTable();
    } catch (error) {
      console.error('❌ Error generating team:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualTeamAssign = async () => {
    if (!currentRequestId || !manualTeamId) return;

    try {
      setIsLoading(true);
      console.log('🔄 Manually assigning team:', { requestId: currentRequestId, teamId: manualTeamId.trim() });
      
      await api.post(`/admin/access-requests/${currentRequestId}/approve`, {
        teamId: manualTeamId.trim(),
        adminId: localStorage.getItem('userId')
      });

      console.log('✅ Team assigned successfully');
      handleCloseDialog();
      refreshTable();
    } catch (error) {
      console.error('❌ Error assigning team:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTeamIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManualTeamId(e.target.value.trim());
  };

  return (
    <Box sx={{ p: 3 }}>
      <StyledTabs value={currentTab} onChange={handleTabChange}>
        <StyledTab label="Pending Requests" />
        <StyledTab label="Rejected Requests" />
      </StyledTabs>

      <AccessRequestsTable
        status={currentTab === 0 ? 'pending' : 'rejected'}
        onAssignTeam={handleAssignTeam}
        refreshKey={refreshKey}
        onTableUpdate={refreshTable}
      />

      <StyledDialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Assign Team ID</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value as AssignOption)}
          >
            <FormControlLabel
              value="auto"
              control={
                <Radio 
                  sx={{ 
                    color: 'rgba(220, 251, 1, 0.7)',
                    '&.Mui-checked': {
                      color: '#DCFB01',
                    },
                  }} 
                />
              }
              label="Generate new Team ID automatically"
            />
            <FormControlLabel
              value="manual"
              control={
                <Radio 
                  sx={{ 
                    color: 'rgba(220, 251, 1, 0.7)',
                    '&.Mui-checked': {
                      color: '#DCFB01',
                    },
                  }} 
                />
              }
              label="Enter Team ID manually"
            />
          </RadioGroup>
          
          {selectedOption === 'manual' && (
            <Box sx={{ mt: 2 }}>
              <StyledTextField
                fullWidth
                label="Team ID"
                value={manualTeamId}
                onChange={handleTeamIdChange}
                variant="outlined"
                placeholder="Enter Team ID"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  }
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            variant="contained"
            disabled={isLoading || (selectedOption === 'manual' && !manualTeamId)}
            sx={{
              bgcolor: '#DCFB01',
              color: '#000',
              '&:hover': {
                bgcolor: 'rgba(220, 251, 1, 0.8)',
              },
              '&:disabled': {
                bgcolor: 'rgba(220, 251, 1, 0.3)',
              }
            }}
          >
            {isLoading ? 'Processing...' : 'Confirm'}
          </Button>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
};

export default AccessRequests; 