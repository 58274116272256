import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  CircularProgress,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Lock as LockIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import api from '../../services/api';
import { SelectChangeEvent } from '@mui/material/Select';

interface Admin {
  id: number;
  username: string;
  email: string;
  role: 'admin' | 'super_admin';
  created_at: string;
}

interface FormData {
  username: string;
  email: string;
  password: string;
  role: 'admin' | 'super_admin';
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  marginTop: theme.spacing(3),
}));

const RoleChip = styled(Chip)<{ role: string }>(({ theme, role }) => ({
  borderRadius: '8px',
  fontWeight: 500,
  ...(role === 'super_admin' && {
    backgroundColor: 'rgba(220, 251, 1, 0.1)',
    color: '#DCFB01',
  }),
  ...(role === 'admin' && {
    backgroundColor: 'rgba(121, 62, 238, 0.1)',
    color: '#793EEE',
  }),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
  },
  '& .MuiDialogTitle-root': {
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(220, 251, 1, 0.7)',
    '&.Mui-focused': {
      color: '#DCFB01',
    },
  },
});

const StyledSelect = styled(Select)({
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(220, 251, 1, 0.2)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(220, 251, 1, 0.3)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#DCFB01',
  },
  '& .MuiSelect-icon': {
    color: '#DCFB01',
  },
});

const ManageAdmins: React.FC = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);
  const [formData, setFormData] = useState<FormData>({
    username: '',
    email: '',
    password: '',
    role: 'admin',
  });
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    console.log('🔄 ManageAdmins component mounted');
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/admin');
      setAdmins(response.data);
    } catch (error) {
      console.error('❌ Error fetching admins:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (admin?: Admin) => {
    if (admin) {
      setSelectedAdmin(admin);
      setFormData({
        username: admin.username,
        email: admin.email,
        password: '',
        role: admin.role,
      });
    } else {
      setSelectedAdmin(null);
      setFormData({
        username: '',
        email: '',
        password: '',
        role: 'admin',
      });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAdmin(null);
    setFormData({
      username: '',
      email: '',
      password: '',
      role: 'admin',
    });
  };

  const handleCreateAdmin = async () => {
    try {
      await api.post('/admin/admin', formData);
      fetchAdmins();
      setDialogOpen(false);
      setFormData({
        username: '',
        email: '',
        password: '',
        role: 'admin',
      });
    } catch (error) {
      console.error('Failed to create admin:', error);
    }
  };

  const handleUpdateAdmin = async () => {
    if (!selectedAdmin) return;
    try {
      await api.put(`/admin/admin/${selectedAdmin.id}`, formData);
      fetchAdmins();
      setDialogOpen(false);
      setFormData({
        username: '',
        email: '',
        password: '',
        role: 'admin',
      });
    } catch (error) {
      console.error('Failed to update admin:', error);
    }
  };

  const handleDeleteAdmin = async (id: number) => {
    try {
      await api.delete(`/admin/admin/${id}`);
      fetchAdmins();
    } catch (error) {
      console.error('Failed to delete admin:', error);
    }
  };

  const handleOpenPasswordDialog = (admin: Admin) => {
    setSelectedAdmin(admin);
    setNewPassword('');
    setPasswordError(null);
    setPasswordDialogOpen(true);
  };

  const handleClosePasswordDialog = () => {
    setPasswordDialogOpen(false);
    setSelectedAdmin(null);
    setNewPassword('');
    setPasswordError(null);
  };

  const handlePasswordChange = async () => {
    if (!selectedAdmin || !newPassword) return;
    try {
      await api.put(`/admin/admin/${selectedAdmin.id}/password`, {
        newPassword
      });
      setPasswordDialogOpen(false);
      setNewPassword('');
      setPasswordError('');
    } catch (error) {
      console.error('Failed to change password:', error);
      setPasswordError('Failed to change password');
    }
  };

  return (
    <Box p={3}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" sx={{ color: '#DCFB01' }}>
              Manage Admins
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
              sx={{
                backgroundColor: '#DCFB01',
                color: '#121212',
                '&:hover': {
                  backgroundColor: '#793EEE',
                  color: '#FFFFFF',
                },
              }}
            >
              Add Admin
            </Button>
          </Box>

          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((admin) => (
                  <TableRow key={admin.id}>
                    <TableCell>{admin.username}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>
                      <RoleChip
                        label={admin.role.toUpperCase()}
                        role={admin.role}
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(admin.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleOpenDialog(admin)}
                        sx={{ color: '#DCFB01' }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleOpenPasswordDialog(admin)}
                        sx={{ color: '#DCFB01' }}
                      >
                        <LockIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteAdmin(admin.id)}
                        sx={{ color: '#793EEE' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>

          <StyledDialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ color: '#DCFB01' }}>
              {selectedAdmin ? 'Edit Admin' : 'Add New Admin'}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <StyledTextField
                  label="Username"
                  value={formData.username}
                  onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                  fullWidth
                />
                <StyledTextField
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  fullWidth
                />
                {!selectedAdmin && (
                  <StyledTextField
                    label="Password"
                    type="password"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    fullWidth
                  />
                )}
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'rgba(220, 251, 1, 0.7)' }}>Role</InputLabel>
                  <StyledSelect
                    value={formData.role}
                    onChange={(event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
                      const value = event.target.value as 'admin' | 'super_admin';
                      setFormData({ ...formData, role: value });
                    }}
                    label="Role"
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="super_admin">Super Admin</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleCloseDialog}
                sx={{ 
                  color: '#DCFB01',
                  '&:hover': {
                    backgroundColor: 'rgba(220, 251, 1, 0.1)',
                  },
                }}
              >
                Cancel
              </Button>
              <Button 
                onClick={selectedAdmin ? handleUpdateAdmin : handleCreateAdmin}
                variant="contained"
                sx={{
                  backgroundColor: '#DCFB01',
                  color: '#121212',
                  '&:hover': {
                    backgroundColor: '#793EEE',
                    color: '#FFFFFF',
                  },
                }}
              >
                {selectedAdmin ? 'Update' : 'Create'}
              </Button>
            </DialogActions>
          </StyledDialog>

          <StyledDialog 
            open={passwordDialogOpen} 
            onClose={handleClosePasswordDialog}
            maxWidth="sm" 
            fullWidth
          >
            <DialogTitle sx={{ color: '#DCFB01' }}>
              Change Password for {selectedAdmin?.username}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <StyledTextField
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!passwordError}
                  helperText={passwordError}
                  fullWidth
                  autoFocus
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleClosePasswordDialog}
                sx={{ 
                  color: '#DCFB01',
                  '&:hover': {
                    backgroundColor: 'rgba(220, 251, 1, 0.1)',
                  },
                }}
              >
                Cancel
              </Button>
              <Button 
                onClick={handlePasswordChange}
                variant="contained"
                sx={{
                  backgroundColor: '#DCFB01',
                  color: '#121212',
                  '&:hover': {
                    backgroundColor: '#793EEE',
                    color: '#FFFFFF',
                  },
                }}
              >
                Change Password
              </Button>
            </DialogActions>
          </StyledDialog>
        </>
      )}
    </Box>
  );
};

export default ManageAdmins; 