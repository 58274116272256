import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  TextField,
  InputAdornment,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ContentCopy as ContentCopy,
  Public as PublicIcon,
  Lock as LockIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Product, ProductStatus, ProductType } from '../../types/products';
import api from '../../services/api';
import ProductDialog from '../../components/ProductDialog/ProductDialog';
import { useNavigate, useLocation } from 'react-router-dom';
import { PRODUCT_ICONS } from '../../constants/productIcons';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  '& .MuiTableCell-root': {
    borderColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      borderRadius: '12px',
    },
    color: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px 14px 0',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: '16px',
    color: 'rgba(255, 255, 255, 0.7)'
  },
}));

const StatusChip = styled(Chip)<{ status: ProductStatus }>(({ theme, status }) => ({
  borderRadius: '8px',
  fontWeight: 500,
  ...(status === 'active' && {
    backgroundColor: 'rgba(220, 251, 1, 0.1)',
    color: '#DCFB01',
  }),
  ...(status === 'beta' && {
    backgroundColor: 'rgba(255, 171, 0, 0.1)',
    color: '#FFAB00',
  }),
  ...(status === 'deprecated' && {
    backgroundColor: 'rgba(121, 62, 238, 0.1)',
    color: '#793EEE',
  }),
  ...(status === 'soon' && {
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
    color: '#2196F3',
  }),
}));

const PRODUCT_STATUSES: ProductStatus[] = ['active', 'deprecated', 'beta', 'soon'];
const PRODUCT_TYPES = [
  'AUDIO_ANALYTICS',
  'TEXT_GENERATION',
  'CUSTOMER_SUPPORT',
  'AI_CALL_CENTER',
  'HR_TALENT_CATALYST',
  'AI_IMAGE_GENERATOR'
] as const;

const Products: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cloneProduct = location.state?.cloneProduct;
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [statusFilter, setStatusFilter] = useState<'all' | ProductStatus>('all');
  const [typeFilter, setTypeFilter] = useState<'all' | ProductType>('all');
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 10;
  const [cloneData, setCloneData] = useState<Partial<Product> | undefined>(undefined);

  useEffect(() => {
    fetchProducts();
  }, [page, statusFilter, typeFilter, searchTerm]);

  useEffect(() => {
    if (cloneProduct) {
      handleOpenDialog(undefined, cloneProduct);
      window.history.replaceState({}, document.title);
    }
  }, [cloneProduct]);

  const fetchProducts = async () => {
    try {
      console.log('📋 Fetching products with params:', {
        page,
        limit: rowsPerPage,
        status: statusFilter,
        type: typeFilter,
        search: searchTerm
      });

      const response = await api.get('/admin/products', {
        params: {
          page,
          limit: rowsPerPage,
          status: statusFilter !== 'all' ? statusFilter : undefined,
          type: typeFilter !== 'all' ? typeFilter : undefined,
          search: searchTerm,
        }
      });

      console.log('✅ Products loaded:', {
        total: response.data.total,
        count: response.data.products.length
      });

      setProducts(response.data.products);
      setTotalCount(response.data.total);
      setError(null);
    } catch (err) {
      console.error('❌ Error fetching products:', err);
      setError('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (product?: Product, cloneData?: Product) => {
    setSelectedProduct(product);
    setDialogOpen(true);
    if (cloneData) {
      const { id, createdAt, updatedAt, ...cloneWithoutMetadata } = cloneData;
      const clonedData = {
        ...cloneWithoutMetadata,
        name: `${cloneData.name}`
      };
      setSelectedProduct(undefined);
      setCloneData(clonedData);
    }
  };

  const handleCloseDialog = () => {
    setSelectedProduct(undefined);
    setCloneData(undefined);
    setDialogOpen(false);
  };

  const handleSaveProduct = async (productData: Partial<Product>) => {
    try {
      console.log('💾 Saving product:', productData);
      
      await fetchProducts();
    } catch (error) {
      console.error('Error saving product:', error);
      setError('Failed to save product');
    }
  };

  const handleDeleteProduct = async (productId: string) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await api.delete(`/admin/products/${productId}`);
        await fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
        setError('Failed to delete product');
      }
    }
  };

  const handleViewProduct = (productId: string) => {
    navigate(`/products/${productId}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const filteredProducts = products.filter(product => {
    const matchesSearch = 
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.webhook_url.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = statusFilter === 'all' || product.status === statusFilter;
    const matchesType = typeFilter === 'all' || product.type === typeFilter;

    return matchesSearch && matchesStatus && matchesType;
  });

  const formatProductType = (type: string) => {
    return type
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    background: theme.palette.mode === 'dark' 
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'white',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    overflow: 'hidden',
  }));

  interface IconType {
    type: string;
    icon: React.ComponentType;
    label: string;
  }

  const ProductCard = ({ product }: { product: Product }) => (
    <StyledPaper>
      {product.image_url && (
        <Box sx={{ 
          height: 200, 
          overflow: 'hidden',
          borderRadius: '12px 12px 0 0'
        }}>
          <img 
            src={product.image_url} 
            alt={product.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>
      )}
      
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          {product.icon_type && (
            <Box sx={{ 
              p: 1.5, 
              bgcolor: 'rgba(220, 251, 1, 0.1)',
              borderRadius: '8px'
            }}>
              {React.createElement(
                PRODUCT_ICONS.find((icon: IconType) => icon.type === product.icon_type)?.icon || 'div',
                { sx: { color: '#DCFB01', fontSize: 24 } }
              )}
            </Box>
          )}
          <Typography variant="h6">{product.name}</Typography>
        </Box>
      </Box>
    </StyledPaper>
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4">
          Products
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          sx={{ 
            bgcolor: '#DCFB01',
            color: '#000',
            '&:hover': {
              bgcolor: 'rgba(220, 251, 1, 0.8)',
            }
          }}
        >
          Add Product
        </Button>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <SearchField
          fullWidth
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value as 'all' | ProductStatus)}
            label="Status"
            sx={{
              borderRadius: '12px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(220, 251, 1, 0.3)',
              },
              '& .MuiMenu-paper': {
                borderRadius: '12px',
              },
              '& .MuiSelect-select': {
                borderRadius: '12px',
              }
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: '12px',
                  bgcolor: 'rgba(30, 30, 30, 0.95)',
                  '& .MuiMenuItem-root': {
                    '&:hover': {
                      bgcolor: 'rgba(220, 251, 1, 0.05)',
                    },
                  },
                },
              },
            }}
          >
            <MenuItem value="all">All Statuses</MenuItem>
            {PRODUCT_STATUSES.map((status) => (
              <MenuItem key={status} value={status}>
                {status.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value as 'all' | ProductType)}
            label="Type"
            sx={{
              borderRadius: '12px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(220, 251, 1, 0.3)',
              },
              '& .MuiMenu-paper': {
                borderRadius: '12px',
              },
              '& .MuiSelect-select': {
                borderRadius: '12px',
              }
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: '12px',
                  bgcolor: 'rgba(30, 30, 30, 0.95)',
                  '& .MuiMenuItem-root': {
                    '&:hover': {
                      bgcolor: 'rgba(220, 251, 1, 0.05)',
                    },
                  },
                },
              },
            }}
          >
            <MenuItem value="all">All Types</MenuItem>
            {PRODUCT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type.replace(/_/g, ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Visibility</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow 
                key={product.id}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(220, 251, 1, 0.05)',
                  },
                }}
                onClick={() => handleViewProduct(product.id)}
              >
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: '#DCFB01',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 1,
                      '&::before': {
                        content: '""',
                        display: 'block',
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: 'currentColor',
                        opacity: 0.7
                      }
                    }}
                  >
                    {formatProductType(product.type)}
                  </Typography>
                </TableCell>
                <TableCell>{product.version}</TableCell>
                <TableCell>
                  <StatusChip
                    label={product.status.toUpperCase()}
                    status={product.status}
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {product.visibility === 'public' ? (
                      <PublicIcon sx={{ color: '#DCFB01', fontSize: '1.2rem' }} />
                    ) : (
                      <LockIcon sx={{ color: '#DCFB01', fontSize: '1.2rem' }} />
                    )}
                    <Typography sx={{ textTransform: 'capitalize' }}>
                      {product.visibility || 'public'}
                    </Typography>
                    {product.visibility === 'private' && product.allowedTeams && (
                      <Chip
                        size="small"
                        label={`${product.allowedTeams.length} teams`}
                        sx={{
                          ml: 1,
                          backgroundColor: 'rgba(220, 251, 1, 0.1)',
                          color: '#DCFB01',
                        }}
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  {product.createdAt 
                    ? new Date(product.createdAt).toLocaleDateString()
                    : '-'
                  }
                </TableCell>
                <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                  <IconButton 
                    size="small" 
                    sx={{ color: '#DCFB01' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleViewProduct(product.id);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    sx={{ color: '#DCFB01' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDialog(product);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    sx={{ color: '#DCFB01' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteProduct(product.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
        sx={{
          '.MuiTablePagination-select': {
            color: '#fff',
          },
          '.MuiTablePagination-selectIcon': {
            color: '#DCFB01',
          },
          '.MuiTablePagination-displayedRows': {
            color: '#fff',
          },
        }}
      />

      <ProductDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveProduct}
        product={selectedProduct}
        cloneData={cloneData}
      />
    </Box>
  );
};

export default Products; 