import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Chip,
  IconButton,
  SelectChangeEvent,
  InputAdornment,
  FormControlLabel,
  Switch,
  Autocomplete,
} from '@mui/material';
import { Close as CloseIcon, Add as AddIcon, ContentCopy as ContentCopyIcon, Link as LinkIcon, Public, Lock, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Product, ProductType, ProductStatus, ProductFeature, ProductVisibility, Team } from '../../types/products';
import { MetricsTemplate } from '../../types/metrics';
import { productsApi } from '../../services/productsApi';
import api from '../../services/api';
import { PRODUCT_ICONS } from '../../constants/productIcons';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
  },
  '& .MuiDialogTitle-root': {
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(220, 251, 1, 0.7)',
    '&.Mui-focused': {
      color: '#DCFB01',
    },
  },
});

const StyledSelect = styled(Select)({
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(220, 251, 1, 0.2)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(220, 251, 1, 0.3)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#DCFB01',
  },
  '& .MuiSelect-icon': {
    color: 'rgba(220, 251, 1, 0.7)',
  },
  '& .MuiMenu-paper': {
    borderRadius: '12px',
  },
  '& .MuiSelect-select': {
    borderRadius: '12px',
  }
});

interface ProductDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (product: Partial<Product>) => Promise<void>;
  product?: Product;
  cloneData?: Partial<Product>;
}

const PRODUCT_TYPES: ProductType[] = [
  'AUDIO_ANALYTICS',
  'TEXT_GENERATION',
  'CUSTOMER_SUPPORT',
  'AI_CALL_CENTER',
  'HR_TALENT_CATALYST',
  'AI_IMAGE_GENERATOR'
];

const PRODUCT_STATUSES: ProductStatus[] = ['active', 'deprecated', 'beta', 'soon'];

// Добавим новый тип для настроек метрик
interface MetricSetting {
  enabled: boolean;
  limit?: number;
  alert_threshold?: number;
}

interface TeamResponse {
  team_id: string;
  name: string;
  // другие поля, которые могут прийти из API
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  open,
  onClose,
  onSave,
  product,
  cloneData
}) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  const initialFormData: Partial<Product> = {
    name: '',
    description: '',
    type: 'AUDIO_ANALYTICS',
    version: '1.0.0',
    status: 'active',
    webhook_url: '',
    lago_code: '',
    features: [],
    metricsSettings: {},
    pricing_tiers: [],
    visibility: 'private',
    icon_type: '',
    image_url: '',
    allowedTeams: []
  };

  const [formData, setFormData] = useState<Partial<Product>>(initialFormData);
  const [newFeature, setNewFeature] = useState<Partial<ProductFeature>>({
    name: '',
    description: '',
    isEnabled: true
  });
  const [availableMetrics, setAvailableMetrics] = useState<MetricsTemplate[]>([]);

  useEffect(() => {
    if (open) {
      fetchTeams();
      if (product) {
        console.log('Setting product data:', product);
        setFormData({
          ...product,
          features: Array.isArray(product.features) ? product.features : [],
          visibility: product.visibility || 'public',
          icon_type: product.icon_type || '',
          image_url: product.image_url || ''
        });
        if (product.allowedTeams) {
          setSelectedTeams(product.allowedTeams.map(team => team.teamId.toString()));
        }
      } else if (cloneData) {
        const {
          id,
          createdAt,
          updatedAt,
          ...cloneWithoutMetadata
        } = cloneData;

        setFormData({
          ...cloneWithoutMetadata,
          features: Array.isArray(cloneData.features) ? cloneData.features : [],
          visibility: 'public',
          icon_type: cloneData.icon_type || '',
          image_url: cloneData.image_url || '',
          metricsSettings: cloneData.metricsSettings || {},
          pricing_tiers: cloneData.pricing_tiers || []
        });
        setSelectedTeams([]);
      } else {
        setFormData(initialFormData);
        setSelectedTeams([]);
      }
    }
  }, [open, product, cloneData]);

  useEffect(() => {
    if (formData.type) {
      api.get<MetricsTemplate[]>(`/admin/metrics/templates`, {
        params: { productType: formData.type }
      })
      .then(response => {
        const metricsConfig = response.data;
        // Исправляем типизацию параметров reduce
        const initialMetrics = metricsConfig.reduce((acc: Record<string, MetricSetting>, metric: MetricsTemplate) => ({
          ...acc,
          [metric.code]: {
            enabled: false,
            limit: 0,
            alert_threshold: 80,
            unit: metric.unit
          }
        }), {});

        setFormData(prev => ({
          ...prev,
          metricsSettings: {
            ...initialMetrics,
            ...prev.metricsSettings
          }
        }));
        setAvailableMetrics(metricsConfig);
      })
      .catch(error => {
        console.error('Failed to fetch metrics templates:', error);
      });
    }
  }, [formData.type]);

  const handleTextChange = (field: keyof Product) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSelectChange = (field: keyof Product) => (
    event: SelectChangeEvent<unknown>, 
    child: React.ReactNode
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value as string
    }));
  };

  const handleAddFeature = () => {
    if (newFeature.name && newFeature.description) {
      setFormData(prev => ({
        ...prev,
        features: [
          ...(Array.isArray(prev.features) ? prev.features : []),
          {
            id: Date.now().toString(),
            ...newFeature,
            isEnabled: true
          } as ProductFeature
        ]
      }));
      setNewFeature({ name: '', description: '', isEnabled: true });
    }
  };

  const handleRemoveFeature = (featureId: string) => {
    setFormData(prev => ({
      ...prev,
      features: Array.isArray(prev.features) 
        ? prev.features.filter(f => f.id !== featureId)
        : []
    }));
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setNewFeature({ name: '', description: '', isEnabled: true });
    onClose();
  };

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('/admin/teams', {
        params: {
          limit: 100 // Увеличим лимит для получения всех команд
        }
      });
      
      console.log('Fetched teams:', data);
      
      if (data.teams) {
        const teamsData = data.teams.map((team: any) => ({
          id: team.team_id,
          name: team.name
        }));
        setTeams(teamsData);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
      setTeams([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      console.log('Saving product with data:', {
        ...formData,
        icon_type: formData.icon_type,
        image_url: formData.image_url,
        visibility: formData.visibility,
        allowedTeams: selectedTeams
      });

      if (product) {
        // Обновляем продукт
        await api.put(`/admin/products/${product.id}`, {
          ...formData,
          icon_type: formData.icon_type,
          image_url: formData.image_url
        });

        // Отдельно обновляем доступ команд
        await api.put(`/admin/products/${product.id}/team-access`, {
          teamIds: formData.visibility === 'private' ? selectedTeams : []
        });

      } else {
        await productsApi.createProduct({
          ...formData,
          icon_type: formData.icon_type,
          image_url: formData.image_url
        });
      }

      onSave(formData);
      handleClose();
    } catch (error) {
      console.error('Failed to save product:', error);
    } finally {
      setLoading(false);
    }
  };

  // Добавим обработчик изменения настроек метрик
  const handleMetricSettingChange = (metricCode: string, field: keyof MetricSetting, value: any) => {
    setFormData(prev => ({
      ...prev,
      metricsSettings: {
        ...prev.metricsSettings,
        [metricCode]: {
          ...(prev.metricsSettings?.[metricCode] || { enabled: false }),
          [field]: value
        }
      }
    }));
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploadingImage(true);
      
      // 1. Получаем URL для загрузки
      const fileType = file.name.split('.').pop() || '';
      const { data } = await api.get('/admin/products/files/upload-url', {
        params: { fileType }
      });

      console.log('🔗 Received upload URL:', {
        fileType,
        contentType: file.type,
        size: file.size
      });

      // 2. Загружаем файл
      const uploadResponse = await fetch(data.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file
      });

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text();
        console.error('❌ Upload failed:', {
          status: uploadResponse.status,
          statusText: uploadResponse.statusText,
          error: errorText
        });
        throw new Error(`Upload failed: ${errorText}`);
      }

      console.log('✅ File uploaded successfully:', data.publicUrl);

      // 3. Проверяем доступность файла
      const checkResponse = await fetch(data.publicUrl, { method: 'HEAD' });
      
      if (!checkResponse.ok) {
        throw new Error('Uploaded file is not accessible');
      }

      // 4. Обновляем форму
      setFormData(prev => ({
        ...prev,
        image_url: data.publicUrl
      }));

    } catch (error) {
      console.error('❌ Upload error:', error);
    } finally {
      setUploadingImage(false);
    }
  };

  const handleImageDelete = async () => {
    if (!product?.id || !formData.image_url) return;

    try {
      setUploadingImage(true);
      
      await api.delete(`/admin/products/${product.id}/image`, {
        data: { imageUrl: formData.image_url }
      });

      setFormData(prev => ({
        ...prev,
        image_url: ''
      }));
    } catch (error) {
      console.error('Failed to delete image:', error);
    } finally {
      setUploadingImage(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: '#DCFB01', fontSize: '1.5rem', fontWeight: 500 }}>
            {product ? 'Edit Product' : 'Add New Product'}
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: '#DCFB01' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <StyledTextField
            label="Name"
            fullWidth
            value={formData.name}
            onChange={handleTextChange('name')}
          />
          <StyledTextField
            label="Webhook URL"
            fullWidth
            value={formData.webhook_url || ''}
            onChange={handleTextChange('webhook_url')}
            placeholder="https://api.example.com/webhook"
            helperText="URL endpoint for product integration"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon sx={{ color: 'rgba(220, 251, 1, 0.7)' }} />
                </InputAdornment>
              ),
              endAdornment: formData.webhook_url && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => formData.webhook_url && navigator.clipboard.writeText(formData.webhook_url)}
                    sx={{ color: 'rgba(220, 251, 1, 0.7)' }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <StyledTextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={formData.description}
            onChange={handleTextChange('description')}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: 'rgba(220, 251, 1, 0.7)' }}>Type</InputLabel>
              <StyledSelect
                value={formData.type || ''}
                onChange={handleSelectChange('type')}
                label="Type"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: '12px',
                      bgcolor: 'rgba(30, 30, 30, 0.95)',
                      '& .MuiMenuItem-root': {
                        '&:hover': {
                          bgcolor: 'rgba(220, 251, 1, 0.05)',
                        },
                      },
                    },
                  },
                }}
              >
                {PRODUCT_TYPES.map(type => (
                  <MenuItem key={type} value={type}>
                    {type.replace(/_/g, ' ')}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ color: 'rgba(220, 251, 1, 0.7)' }}>Status</InputLabel>
              <StyledSelect
                value={formData.status || ''}
                onChange={handleSelectChange('status')}
                label="Status"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: '12px',
                      bgcolor: 'rgba(30, 30, 30, 0.95)',
                      '& .MuiMenuItem-root': {
                        '&:hover': {
                          bgcolor: 'rgba(220, 251, 1, 0.05)',
                        },
                      },
                    },
                  },
                }}
              >
                {PRODUCT_STATUSES.map(status => (
                  <MenuItem key={status} value={status}>
                    {status.toUpperCase()}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Box>
          <StyledTextField
            label="Version"
            fullWidth
            value={formData.version}
            onChange={handleTextChange('version')}
          />
          <StyledTextField
            label="Lago Code"
            fullWidth
            value={formData.lago_code || ''}
            onChange={handleTextChange('lago_code')}
            placeholder="product_code_in_lago"
            helperText="Code used to identify product in Lago"
          />

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, color: '#DCFB01' }}>
              Visibility Settings
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Visibility</InputLabel>
              <StyledSelect
                value={formData.visibility || 'public'}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  visibility: e.target.value as ProductVisibility
                }))}
                startAdornment={
                  <InputAdornment position="start">
                    {formData.visibility === 'public' ? <Public /> : <Lock />}
                  </InputAdornment>
                }
              >
                <MenuItem value="public">Public (Available to all teams)</MenuItem>
                <MenuItem value="private">Private (Selected teams only)</MenuItem>
              </StyledSelect>
            </FormControl>

            {formData.visibility === 'private' && (
              <Box sx={{ mt: 2 }}>
                <Autocomplete
                  multiple
                  options={teams}
                  getOptionLabel={(option: Team) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={teams.filter(team => selectedTeams.includes(team.id)) || []}
                  onChange={(_, newValue: Team[]) => {
                    console.log('Selected teams:', newValue);
                    setSelectedTeams(newValue.map(team => team.id));
                  }}
                  loading={loading}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Select Teams"
                      placeholder="Choose teams that can access this product"
                    />
                  )}
                  renderTags={(value: Team[], getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={key}
                          {...tagProps}
                          label={option.name}
                          sx={{
                            backgroundColor: 'rgba(220, 251, 1, 0.1)',
                            color: '#DCFB01',
                            '& .MuiChip-deleteIcon': {
                              color: '#DCFB01',
                              '&:hover': {
                                color: 'rgba(220, 251, 1, 0.8)',
                              }
                            }
                          }}
                        />
                      );
                    })
                  }
                />
              </Box>
            )}
          </Box>

          {/* Features Section */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, color: '#DCFB01' }}>
              Features
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <StyledTextField
                label="Feature Name"
                size="small"
                value={newFeature.name}
                onChange={(e) => setNewFeature(prev => ({ ...prev, name: e.target.value }))}
              />
              <StyledTextField
                label="Description"
                size="small"
                value={newFeature.description}
                onChange={(e) => setNewFeature(prev => ({ ...prev, description: e.target.value }))}
              />
              <Button
                variant="contained"
                onClick={handleAddFeature}
                sx={{ 
                  bgcolor: '#DCFB01',
                  color: '#000',
                  '&:hover': {
                    bgcolor: 'rgba(220, 251, 1, 0.8)',
                  }
                }}
              >
                <AddIcon />
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {Array.isArray(formData.features) && formData.features.map((feature) => (
                <Chip
                  key={feature.id}
                  label={feature.name}
                  onDelete={() => handleRemoveFeature(feature.id)}
                  sx={{
                    bgcolor: 'rgba(220, 251, 1, 0.1)',
                    color: '#DCFB01',
                    '& .MuiChip-deleteIcon': {
                      color: '#DCFB01',
                      '&:hover': {
                        color: 'rgba(220, 251, 1, 0.8)',
                      }
                    }
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* Metrics Configuration */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, color: '#DCFB01' }}>
              Metrics Configuration
            </Typography>
            {availableMetrics.map(metric => (
              <Box 
                key={metric.id} 
                sx={{ 
                  mb: 3,
                  p: 2,
                  backgroundColor: 'rgba(220, 251, 1, 0.05)',
                  borderRadius: '12px',
                  border: '1px solid rgba(220, 251, 1, 0.1)'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {metric.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {metric.description}
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.metricsSettings?.[metric.code]?.enabled ?? false}
                        onChange={(e) => handleMetricSettingChange(metric.code, 'enabled', e.target.checked)}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#DCFB01',
                            '&:hover': {
                              backgroundColor: 'rgba(220, 251, 1, 0.1)',
                            },
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: 'rgba(220, 251, 1, 0.5)',
                          },
                        }}
                      />
                    }
                    label="Enable"
                  />
                </Box>

                {formData.metricsSettings?.[metric.code]?.enabled && (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <StyledTextField
                      label="Limit"
                      type="number"
                      size="small"
                      value={formData.metricsSettings?.[metric.code]?.limit || ''}
                      onChange={(e) => handleMetricSettingChange(
                        metric.code,
                        'limit',
                        parseInt(e.target.value) || undefined
                      )}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{metric.unit}</InputAdornment>,
                      }}
                    />
                    <StyledTextField
                      label="Alert Threshold"
                      type="number"
                      size="small"
                      value={formData.metricsSettings?.[metric.code]?.alert_threshold || ''}
                      onChange={(e) => handleMetricSettingChange(
                        metric.code,
                        'alert_threshold',
                        parseInt(e.target.value) || undefined
                      )}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>

          {/* Выбор иконки */}
          <FormControl fullWidth>
            <InputLabel>Icon Type</InputLabel>
            <StyledSelect
              value={formData.icon_type || ''}
              onChange={handleSelectChange('icon_type')}
              label="Icon Type"
            >
              {PRODUCT_ICONS.map(({ type, label }) => (
                <MenuItem key={type} value={type}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {React.createElement(PRODUCT_ICONS.find(icon => icon.type === type)?.icon || 'div')}
                    <span>{label}</span>
                  </Box>
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>

          {/* Загрузка изображения */}
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1, color: '#DCFB01' }}>
              Product Image
            </Typography>
            <Box sx={{ 
              position: 'relative',
              width: '100%',
              height: 200,
              borderRadius: '12px',
              overflow: 'hidden',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              border: '2px dashed rgba(220, 251, 1, 0.3)'
            }}>
              {formData.image_url ? (
                <Box sx={{ position: 'relative' }}>
                  <Box
                    component="img"
                    src={formData.image_url}
                    alt="Product"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                  <Box sx={{ 
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    display: 'flex',
                    gap: 1
                  }}>
                    <IconButton
                      onClick={() => document.getElementById('image-upload')?.click()}
                      sx={{ 
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
                      }}
                    >
                      <EditIcon sx={{ color: '#fff' }} />
                    </IconButton>
                    <IconButton
                      onClick={handleImageDelete}
                      sx={{ 
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
                      }}
                    >
                      <DeleteIcon sx={{ color: '#fff' }} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                    id="image-upload"
                  />
                  <label htmlFor="image-upload">
                    <Button
                      component="span"
                      variant="contained"
                      disabled={uploadingImage}
                      sx={{
                        bgcolor: '#DCFB01',
                        color: '#000',
                        '&:hover': {
                          bgcolor: 'rgba(220, 251, 1, 0.8)',
                        }
                      }}
                    >
                      {uploadingImage ? 'Uploading...' : 'Upload Image'}
                    </Button>
                  </label>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ 
          color: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
          }
        }}>
          Cancel
        </Button>
        <Button onClick={handleSave} sx={{ 
          color: '#DCFB01',
          '&:hover': {
            backgroundColor: 'rgba(220, 251, 1, 0.1)',
          }
        }}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ProductDialog; 