import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: '12px',
  '&.MuiAlert-standardSuccess': {
    backgroundColor: 'rgba(220, 251, 1, 0.1)',
    color: '#DCFB01',
    '& .MuiAlert-icon': {
      color: '#DCFB01'
    }
  },
  '&.MuiAlert-standardError': {
    backgroundColor: 'rgba(244, 67, 54, 0.1)',
    color: '#f44336',
    '& .MuiAlert-icon': {
      color: '#f44336'
    }
  }
}));

interface NotificationProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error';
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  open,
  message,
  severity,
  onClose
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <StyledAlert
        onClose={onClose}
        severity={severity}
        variant="standard"
      >
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

export default Notification; 