import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAdminAuth } from '../../context/AdminAuthContext';
import LogoWhite from '../../assets/images/logo_white.png';

const LoginContainer = styled(Box)({
  minHeight: '100vh',
  minWidth: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(45deg, #121212 0%, #1E1E1E 100%)',
  padding: '20px',
  margin: 0,
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '800px',
    height: '800px',
    backgroundImage: `url(${LogoWhite})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: 'translate(-50%, -50%)',
    opacity: 0.02,
    animation: 'rotate 60s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) rotate(360deg)',
    },
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '400px',
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  position: 'relative',
  zIndex: 1,
}));

// Добавляем стили для текстовых полей
const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& input': {
      color: '#FFFFFF',
    },
    '& fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(220, 251, 1, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(220, 251, 1, 0.7)',
    '&.Mui-focused': {
      color: '#DCFB01',
    },
  },
};

const AdminLogin: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAdminAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await login(username, password);
      navigate('/');
    } catch (err) {
      setError('Invalid credentials');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <StyledPaper>
        <Typography
          variant="h4"
          sx={{
            mb: 4,
            textAlign: 'center',
            fontWeight: 700,
            background: 'linear-gradient(90deg, #DCFB01 0%, #793EEE 50%, #DCFB01 100%)',
            backgroundSize: '200% auto',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            animation: 'gradient 9s linear infinite',
            '@keyframes gradient': {
              '0%': {
                backgroundPosition: '0% center',
              },
              '100%': {
                backgroundPosition: '-200% center',
              },
            },
          }}
        >
          Admin Panel
        </Typography>

        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              backgroundColor: 'rgba(220, 251, 1, 0.1)',
              color: '#DCFB01',
            }}
            onClose={() => setError('')}
          >
            {error}
          </Alert>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={loading}
            sx={textFieldStyles}
            autoComplete="username"
            name="username"
            type="text"
            inputProps={{
              autoCapitalize: 'none'
            }}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            sx={textFieldStyles}
            autoComplete="current-password"
            name="password"
          />
          <Button
            variant="contained"
            size="large"
            onClick={handleLogin}
            disabled={loading}
            sx={{ 
              mt: 2,
              backgroundColor: '#DCFB01',
              color: '#121212',
              borderRadius: '12px',
              padding: '12px',
              '&:hover': {
                backgroundColor: '#793EEE',
                color: '#FFFFFF',
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </Box>
      </StyledPaper>
    </LoginContainer>
  );
};

export default AdminLogin; 