import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material';
import { MoreVert, Search } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import api from '../../services/api';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  company_industry: string;
  team_id: string | null;
  created_at: string;
  status: string;
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  '& .MuiTableCell-root': {
    borderColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      borderRadius: '12px',
    },
    color: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px 14px 0',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: '16px',
    color: 'rgba(255, 255, 255, 0.7)'
  },
}));

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const rowsPerPage = 15;

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/users', {
        params: {
          page,
          limit: rowsPerPage,
          search: searchTerm,
        }
      });
      console.log('Received users data:', response.data);
      setUsers(response.data.users);
      setTotalCount(response.data.total);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, searchTerm]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, user: User) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleStatusChange = async (userId: string, newStatus: string) => {
    try {
      const response = await api.put(`/admin/users/${userId}/status`, {
        status: newStatus
      });
      // Обновляем пользователя в списке
      setUsers(users.map(user => 
        user.id === userId ? response.data : user
      ));
      handleMenuClose();
    } catch (error) {
      console.error('Failed to update user status:', error);
    }
  };

  const getStatusDisplay = (status: string) => {
    switch (status) {
      case 'approved':
      case 'active':
        return {
          label: 'ACTIVE',
          color: '#DCFB01'
        };
      case 'rejected':
        return {
          label: 'INACTIVE',
          color: '#793EEE'
        };
      case 'pending':
        return {
          label: 'PENDING',
          color: '#FFAB00'
        };
      default:
        return {
          label: status.toUpperCase(),
          color: '#FFAB00'
        };
    }
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Users
      </Typography>

      <SearchField
        fullWidth
        variant="outlined"
        placeholder="Search users..."
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell>Team ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">Loading...</TableCell>
              </TableRow>
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">No users found</TableCell>
              </TableRow>
            ) : (
              users.map((user) => {
                const statusInfo = getStatusDisplay(user.status);
                return (
                  <TableRow key={user.id}>
                    <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.company_name || '-'}</TableCell>
                    <TableCell>{user.company_industry || '-'}</TableCell>
                    <TableCell>{user.team_id || '-'}</TableCell>
                    <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <span style={{ color: statusInfo.color }}>●</span>
                        {statusInfo.label}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={(e) => handleMenuOpen(e, user)}
                        sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[15]}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            bgcolor: 'rgba(30, 30, 30, 0.95)',
            border: '1px solid rgba(255, 255, 255, 0.05)',
            '& .MuiMenuItem-root': {
              '&:hover': {
                bgcolor: 'rgba(220, 251, 1, 0.05)',
              },
            },
          },
        }}
      >
        <MenuItem 
          onClick={() => handleStatusChange(selectedUser?.id || '', 'pending')}
          disabled={selectedUser?.status === 'pending'}
          sx={{
            color: '#fff',
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.3)',
            },
          }}
        >
          Set Pending
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Users; 