import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  TextField,
  Pagination,
  InputAdornment,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import type { Dayjs } from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';
import api from '../../services/api';
import { Search, AttachMoney, Speed } from '@mui/icons-material';
import styled from '@emotion/styled';

const COLORS = {
  primary: '#DCFB01',
  secondary: '#793EEE',
  background: '#121212',
  cardBg: 'rgba(32, 32, 32, 0.6)',
};

type ModelType = string;

const MODEL_COLORS = {
  'default': '#DCFB01',  // неоново-желтый
  'alternate': '#793EEE' // фиолетовый
};

const MODEL_NAMES: Record<string, string> = {
  'gemma2-9b-it': 'Gemma 2 9B IT',
  'gpt-4o-mini': 'GPT-4O Mini',
  'claude-3-5-sonnet-20241022': 'Claude 3.5 Sonnet',
  'claude-3-5-sonnet-20240620': 'Claude 3.5 Sonnet',
  'gpt-4o': 'GPT-4O',
  'grok-beta': 'Grok Beta',
  'llama-3.2-90b-text-preview': 'Llama 3.2 90B',
  'llama-3.2-11b-text-preview': 'Llama 3.2 11B',
  'llama-3.2-11b-vision-preview': 'Llama 3.2 11B Vision',
  'o1-mini': 'O1 Mini',
  'groq/whisper-large-v3-turbo': 'Groq Whisper Large V3'
};

interface TeamStats {
  teamId: string;
  teamName: string;
  totalRequests: number;
  totalTokens: number;
  totalSpend: number;
  modelsUsage: { [key: string]: number };
  averageLatency: number;
  successRate: number;
  dailyStats: Array<{
    date: string;
    requests: number;
    tokens: number;
    spend: number;
  }>;
}

// Добавляем интерфейс для данных графика
interface ModelUsageData {
  name: string;
  value: number;
  originalName: string;
}

// Обновляем стиль для ChartCard
const ChartCard = styled(Paper)(({ theme }) => ({
  padding: '24px',
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  '& .recharts-wrapper': {
    flex: 1,
  },
  '&:hover': {
    boxShadow: `
      0 0 5px rgba(220, 251, 1, 0.2),
      0 0 10px rgba(220, 251, 1, 0.2),
      0 0 15px rgba(220, 251, 1, 0.1)
    `,
  }
}));

// Обновляем стиль для StatWidget
const StatWidget = styled(Paper)(({ theme }) => ({
  padding: '24px',
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  height: '160px',
  '&:hover': {
    boxShadow: `
      0 0 5px rgba(220, 251, 1, 0.2),
      0 0 10px rgba(220, 251, 1, 0.2),
      0 0 15px rgba(220, 251, 1, 0.1)
    `,
  }
}));

// Добавим интерфейс для группировки моделей
interface ModelGroup {
  name: string;
  models: {
    name: string;
    requests: number;
  }[];
  totalRequests: number;
}

// Функция для группировки моделей
const groupModels = (modelsUsage: { [key: string]: number }): ModelGroup[] => {
  const groups: { [key: string]: ModelGroup } = {};

  Object.entries(modelsUsage).forEach(([model, requests]) => {
    let groupName = 'Other';
    let modelName = model;

    // Определяем группу на основе названия модели
    if (model.startsWith('gpt-') || model.startsWith('o1-mini') || model.startsWith('o1-preview')) {
      groupName = 'OpenAI';
    } else if (model.startsWith('claude-')) {
      groupName = 'Anthropic';
    } else if (model.includes('llama')) {
      groupName = 'Meta';
    } else if (model.startsWith('gemma') || model.startsWith('gemini')) {
      groupName = 'Google';
    } else if (model.startsWith('groq')) {
      groupName = 'Groq';
    } else if (model.startsWith('grok-')) {
      groupName = 'XAI';
    }

    // Очищаем название модели
    modelName = model.split('/').pop() || model;

    if (!groups[groupName]) {
      groups[groupName] = {
        name: groupName,
        models: [],
        totalRequests: 0
      };
    }

    groups[groupName].models.push({
      name: modelName,
      requests
    });
    groups[groupName].totalRequests += requests;
  });

  // Сортируем группы по количеству запросов
  return Object.values(groups).sort((a, b) => b.totalRequests - a.totalRequests);
};

// Обновляем цвета для провайдеров, добавляем XAI
const PROVIDER_COLORS: { [key: string]: string } = {
  'OpenAI': '#DCFB01',    // Неоново-желтый
  'Anthropic': '#793EEE', // Фиолетовый
  'Google': '#DCFB01',    // Неоново-желтый
  'Meta': '#793EEE',      // Фиолетовый
  'Groq': '#DCFB01',      // Неоново-желтый
  'XAI': '#793EEE',       // Фиолетовый
  'Other': '#DCFB01'      // Неоново-желтый
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: '8px',
  '& .MuiToggleButton-root': {
    border: '1px solid rgba(220, 251, 1, 0.2)',
    borderRadius: '12px !important',
    color: 'rgba(220, 251, 1, 0.7)',
    padding: '8px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(220, 251, 1, 0.1)',
      color: '#DCFB01',
    },
    '&.Mui-selected': {
      backgroundColor: '#DCFB01',
      color: '#121212',
      '&:hover': {
        backgroundColor: 'rgba(220, 251, 1, 0.8)',
        color: '#121212',
      },
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)({
  '&.MuiToggleButton-root': {
    borderRadius: '12px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
});

const Statistics: React.FC = () => {
  const [period, setPeriod] = useState<string>('today');
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<TeamStats[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const teamsPerPage = 5;

  const fetchStats = async () => {
    try {
      setLoading(true);
      console.log('📊 Fetching statistics with params:', {
        period,
        startDate: customStartDate?.format('YYYY-MM-DD'),
        endDate: customEndDate?.format('YYYY-MM-DD')
      });

      const response = await api.get('/admin/statistics/teams', {
        params: {
          period,
          startDate: customStartDate?.format('YYYY-MM-DD'),
          endDate: customEndDate?.format('YYYY-MM-DD')
        }
      });

      console.log('✅ Statistics loaded:', {
        teamsCount: response.data.length,
        period,
        dataPreview: response.data.slice(0, 2)
      });

      setStats(response.data);
      setError(null);
    } catch (error) {
      console.error('❌ Error loading statistics:', error);
      setError('Failed to load statistics');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [period, customStartDate, customEndDate]);

  const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
    if (newPeriod !== null) {
      console.log('🔄 Changing period:', {
        from: period,
        to: newPeriod
      });
      setPeriod(newPeriod);
      if (newPeriod !== 'custom') {
        setCustomStartDate(null);
        setCustomEndDate(null);
      }
    }
  };

  // Функция фильтрации команд
  const filteredStats = stats.filter(team => 
    team.teamName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    team.teamId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Пагинация
  const totalPages = Math.ceil(filteredStats.length / teamsPerPage);
  const currentStats = filteredStats.slice(
    (page - 1) * teamsPerPage,
    page * teamsPerPage
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box p={3}>
        <Typography variant="h4" gutterBottom sx={{ color: COLORS.primary }}>
          Teams Statistics
        </Typography>

        <Box mb={4}>
          <StyledToggleButtonGroup
            value={period}
            exclusive
            onChange={handlePeriodChange}
            aria-label="time period"
          >
            <StyledToggleButton value="today">Today</StyledToggleButton>
            <StyledToggleButton value="7d">7 Days</StyledToggleButton>
            <StyledToggleButton value="30d">30 Days</StyledToggleButton>
            <StyledToggleButton value="custom">Custom</StyledToggleButton>
            <StyledToggleButton value="all">All Time</StyledToggleButton>
          </StyledToggleButtonGroup>

          {period === 'custom' && (
            <Box mt={2} display="flex" gap={2}>
              <DatePicker
                label="Start Date"
                value={customStartDate}
                onChange={(newValue) => setCustomStartDate(newValue)}
                slotProps={{
                  textField: {
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: 'rgba(220, 251, 1, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(220, 251, 1, 0.3)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#DCFB01',
                        },
                      },
                    },
                  },
                }}
              />
              <DatePicker
                label="End Date"
                value={customEndDate}
                onChange={(newValue) => setCustomEndDate(newValue)}
                slotProps={{
                  textField: {
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: 'rgba(220, 251, 1, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(220, 251, 1, 0.3)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#DCFB01',
                        },
                      },
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by team name or ID..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                </InputAdornment>
              ),
              sx: {
                borderRadius: '12px',
                '& fieldset': {
                  borderRadius: '12px',
                },
                color: '#fff',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(220, 251, 1, 0.3)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: COLORS.primary,
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              }
            }}
          />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer 
              component={Paper} 
              sx={{ 
                background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                borderRadius: '24px',
                '& .MuiTableCell-root': {
                  borderColor: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Team Name</TableCell>
                    <TableCell>Team ID</TableCell>
                    <TableCell align="right">Requests</TableCell>
                    <TableCell align="right">Tokens</TableCell>
                    <TableCell align="right">Spend ($)</TableCell>
                    <TableCell align="right">Success Rate</TableCell>
                    <TableCell align="right">Avg. Latency</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentStats.map((team) => (
                    <TableRow 
                      key={team.teamId}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(220, 251, 1, 0.05)'
                        }
                      }}
                    >
                      <TableCell sx={{ fontWeight: 500 }}>{team.teamName}</TableCell>
                      <TableCell>{team.teamId}</TableCell>
                      <TableCell align="right">{team.totalRequests.toLocaleString()}</TableCell>
                      <TableCell align="right">{team.totalTokens.toLocaleString()}</TableCell>
                      <TableCell align="right">${team.totalSpend.toFixed(2)}</TableCell>
                      <TableCell 
                        align="right"
                        sx={{
                          color: '#DCFB01'
                        }}
                      >
                        {(team.successRate * 100).toFixed(1)}%
                      </TableCell>
                      <TableCell align="right">{team.averageLatency.toFixed(0)}ms</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mt: 2,
                '& .MuiPagination-ul .MuiPaginationItem-root': {
                  color: '#fff',
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                  '&.Mui-selected': {
                    backgroundColor: COLORS.primary,
                    color: '#000',
                    '&:hover': {
                      backgroundColor: COLORS.primary,
                      opacity: 0.8,
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(220, 251, 1, 0.1)',
                  },
                },
              }}
            >
              <Pagination 
                count={totalPages}
                page={page}
                onChange={(_, newPage) => setPage(newPage)}
                color="primary"
                size="large"
              />
            </Box>
          </Grid>

          {/* Добавляем виджеты статистики */}
          <Grid item xs={12} md={6}>
            <StatWidget>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  sx={{
                    backgroundColor: 'rgba(220, 251, 1, 0.1)',
                    borderRadius: '12px',
                    p: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AttachMoney sx={{ color: COLORS.primary, fontSize: 24 }} />
                </Box>
                <Typography variant="h6" sx={{ color: '#fff' }}>
                  Total Usage ($)
                </Typography>
              </Box>
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    color: COLORS.primary,
                    fontWeight: 600,
                    ml: '52px'
                  }}
                >
                  ${stats.reduce((sum, team) => {
                    const spend = typeof team.totalSpend === 'string' 
                      ? parseFloat(team.totalSpend) 
                      : team.totalSpend;
                    return sum + (isNaN(spend) ? 0 : spend);
                  }, 0).toFixed(2)}
                </Typography>
              </Box>
            </StatWidget>
          </Grid>

          <Grid item xs={12} md={6}>
            <StatWidget>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  sx={{
                    backgroundColor: 'rgba(220, 251, 1, 0.1)',
                    borderRadius: '12px',
                    p: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Speed sx={{ color: COLORS.primary, fontSize: 24 }} />
                </Box>
                <Typography variant="h6" sx={{ color: '#fff' }}>
                  Total API Usage
                </Typography>
              </Box>
              <Box sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'baseline',
                ml: '52px',
              }}>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    color: COLORS.primary,
                    fontWeight: 600,
                  }}
                >
                  {stats.reduce((sum, team) => sum + team.totalRequests, 0).toLocaleString()}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '0.875rem',
                    ml: 1.5,
                    position: 'relative',
                    top: '4px'
                  }}
                >
                  requests
                </Typography>
              </Box>
            </StatWidget>
          </Grid>

          {/* График использования по дням */}
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom sx={{ color: COLORS.primary }}>
                Daily Usage ($)
              </Typography>
              <Box sx={{ flex: 1, minHeight: 0 }}>
                <ResponsiveContainer>
                  <BarChart 
                    data={(() => {
                      // Создаем объединенный массив дат
                      const allDates = new Set<string>();
                      const spendByDate: { [key: string]: { [teamId: string]: number } } = {};
                      const teamNames: { [key: string]: string } = {};

                      stats.forEach(team => {
                        teamNames[team.teamId] = team.teamName;
                        team.dailyStats.forEach(stat => {
                          const date = new Date(stat.date);
                          date.setDate(date.getDate() + 1);
                          // Изменяем формат даты на DD/MM
                          const dateStr = date.toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit'
                          });
                          allDates.add(dateStr);
                          
                          if (!spendByDate[dateStr]) {
                            spendByDate[dateStr] = {};
                          }
                          spendByDate[dateStr][team.teamId] = stat.spend;
                        });
                      });

                      // Преобразуем в формат для графика
                      return Array.from(allDates).sort((a, b) => {
                        // Преобразуем DD/MM в Date для корректной сортировки
                        const [dayA, monthA] = a.split('/');
                        const [dayB, monthB] = b.split('/');
                        const dateA = new Date(2024, parseInt(monthA) - 1, parseInt(dayA));
                        const dateB = new Date(2024, parseInt(monthB) - 1, parseInt(dayB));
                        return dateA.getTime() - dateB.getTime();
                      }).map(date => ({
                        date,
                        ...Object.keys(teamNames).reduce((acc, teamId) => ({
                          ...acc,
                          [teamNames[teamId]]: spendByDate[date]?.[teamId] || 0
                        }), {})
                      }));
                    })()}
                    margin={{ left: 10, right: 10, bottom: 20 }}
                  >
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      stroke={`${COLORS.secondary}25`}
                    />
                    <XAxis 
                      dataKey="date" 
                      stroke="#ffffff"
                      tick={{ fill: '#ffffff' }}
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis 
                      stroke="#ffffff"
                      tick={{ fill: '#ffffff' }}
                      tickFormatter={(value) => `$${value.toFixed(2)}`}
                    />
                    <Tooltip 
                      contentStyle={{
                        background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.98) 0%, rgba(18, 18, 18, 0.98) 100%)',
                        border: `1px solid ${COLORS.secondary}40`,
                        borderRadius: '12px',
                        color: COLORS.primary
                      }}
                      cursor={{ 
                        fill: `${COLORS.primary}10`
                      }}
                      formatter={(value: number, name: string) => [`$${value.toFixed(2)}`, name]}
                    />
                    <Legend 
                      verticalAlign="top"
                      wrapperStyle={{ paddingBottom: '20px' }}
                    />
                    {stats.map((team, index) => (
                      <Bar 
                        key={team.teamId}
                        dataKey={team.teamName}
                        stackId="a"
                        fill={index % 2 === 0 ? COLORS.primary : COLORS.secondary}
                        radius={[4, 4, 0, 0]}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </ChartCard>
          </Grid>

          {/* График использования моделей */}
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom sx={{ color: COLORS.primary }}>
                Models Usage
              </Typography>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                height: '100%',
                overflow: 'auto'
              }}>
                {groupModels(
                  // Объединяем статистику использования моделей всех команд
                  stats.reduce((acc: { [key: string]: number }, team) => {
                    Object.entries(team.modelsUsage).forEach(([model, count]) => {
                      acc[model] = (acc[model] || 0) + count;
                    });
                    return acc;
                  }, {} as { [key: string]: number })
                ).map((group, index) => (
                  <Box 
                    key={group.name}
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      borderRadius: '12px',
                      p: 2
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          color: index % 2 === 0 ? '#DCFB01' : '#793EEE',
                          fontWeight: 600
                        }}
                      >
                        {group.name}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        {group.totalRequests.toLocaleString()} requests
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      {group.models.map((model, modelIndex) => (
                        <Box 
                          key={model.name}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 1,
                            borderRadius: '8px',
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            }
                          }}
                        >
                          <Typography variant="body2" sx={{ color: '#fff' }}>
                            {model.name}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: index % 2 === 0 ? '#DCFB01' : '#793EEE',
                              fontWeight: 500
                            }}
                          >
                            {model.requests.toLocaleString()}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </ChartCard>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default Statistics; 