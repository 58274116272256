import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

interface AdminAuthContextType {
  isAuthenticated: boolean;
  admin: any;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  loading: boolean;
}

const AdminAuthContext = createContext<AdminAuthContextType | undefined>(undefined);

export const AdminAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [admin, setAdmin] = useState<any>(null);
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('adminToken');
      const refreshToken = localStorage.getItem('adminRefreshToken');
      
      console.log('🔐 Initial auth check - Token exists:', !!token);
      console.log('🔄 Refresh token exists:', !!refreshToken);
      console.log('🔍 Token preview:', token ? `${token.substring(0, 10)}...` : 'none');
      console.log('🔍 Refresh token preview:', refreshToken ? `${refreshToken.substring(0, 10)}...` : 'none');

      if (token && refreshToken) {
        try {
          await checkAuth();
          setupRefreshTimer();
        } catch (error) {
          console.error('❌ Initial auth check failed:', error);
          logout();
        }
      } else {
        setLoading(false);
      }
    };

    initializeAuth();

    return () => {
      if (refreshTimer) {
        console.log('🧹 Cleaning up refresh timer');
        clearInterval(refreshTimer);
      }
    };
  }, []);

  const setupRefreshTimer = () => {
    if (refreshTimer) {
      clearInterval(refreshTimer);
    }
    
    const timer = setInterval(async () => {
      try {
        await refreshTokens();
      } catch (error) {
        console.error('Failed to refresh tokens:', error);
      }
    }, 15 * 60 * 1000);
    
    setRefreshTimer(timer);
  };

  const refreshTokens = async () => {
    try {
      console.log('🔄 Attempting to refresh tokens');
      const refreshToken = localStorage.getItem('adminRefreshToken');
      
      if (!refreshToken) {
        console.warn('❌ No refresh token found in storage');
        throw new Error('No refresh token found');
      }

      const response = await api.post('/admin/auth/refresh', {
        refreshToken
      });

      const { token, newRefreshToken, admin } = response.data;

      if (!token || !newRefreshToken) {
        throw new Error('Invalid response: missing tokens');
      }

      console.log('📥 Received new tokens');
      localStorage.setItem('adminToken', token);
      localStorage.setItem('adminRefreshToken', newRefreshToken);
      
      setAdmin(admin);
      setIsAuthenticated(true);
      
      return response.data;
    } catch (error) {
      console.error('❌ Token refresh failed:', error);
      logout();
      throw error;
    }
  };

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await api.get('/admin/auth/me');
      
      setAdmin(response.data.admin);
      setIsAuthenticated(true);
      setupRefreshTimer();
    } catch (error) {
      console.error('❌ Auth check failed:', error);
      const refreshToken = localStorage.getItem('adminRefreshToken');
      if (refreshToken) {
        try {
          await refreshTokens();
        } catch (refreshError) {
          logout();
          throw refreshError;
        }
      } else {
        logout();
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  const login = async (username: string, password: string) => {
    try {
      setLoading(true);
      console.log('🔑 Attempting login for user:', username);
      
      const response = await api.post('/admin/auth/login', {
        username,
        password
      });
      
      const { token, refreshToken, admin } = response.data;
      
      if (!token || !refreshToken) {
        throw new Error('Invalid response: missing tokens');
      }

      console.log('🎉 Login successful!');
      console.log('🔑 Access token:', token.substring(0, 10) + '...');
      console.log('🔑 Refresh token:', refreshToken.substring(0, 10) + '...');
      console.log('👤 Admin data:', admin);
      
      localStorage.setItem('adminToken', token);
      localStorage.setItem('adminRefreshToken', refreshToken);
      console.log('💾 Tokens saved to localStorage');
      
      setAdmin(admin);
      setIsAuthenticated(true);
      setupRefreshTimer();
    } catch (error) {
      console.error('❌ Login failed:', error);
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminRefreshToken');
      setAdmin(null);
      setIsAuthenticated(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await api.post('/admin/auth/logout');
    } catch (error) {
      console.error('❌ Logout error:', error);
    } finally {
      console.log('🚪 Logging out...');
      console.log('🗑️ Removing tokens from localStorage');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminRefreshToken');
      
      if (refreshTimer) {
        console.log('⏰ Clearing refresh timer');
        clearInterval(refreshTimer);
        setRefreshTimer(null);
      }
      
      console.log('🔒 Resetting auth state');
      setAdmin(null);
      setIsAuthenticated(false);
      setLoading(false);
      console.log('✅ Logout completed');
    }
  };

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        
        // Проверяем, что ошибка 401 и запрос еще не пытался обновиться
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          
          try {
            console.log('🔄 Token expired, attempting refresh...');
            const refreshToken = localStorage.getItem('adminRefreshToken');
            
            if (!refreshToken) {
              throw new Error('No refresh token available');
            }

            const response = await api.post('/admin/auth/refresh', {
              refreshToken
            });

            const { token: newToken, refreshToken: newRefreshToken } = response.data;
            
            // Обновляем токены в localStorage
            localStorage.setItem('adminToken', newToken);
            localStorage.setItem('adminRefreshToken', newRefreshToken);
            
            // Обновляем заголовок для следующего запроса
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            
            // Повторяем оригинальный запрос с новым токеном
            return api(originalRequest);
          } catch (refreshError) {
            console.error('❌ Token refresh failed:', refreshError);
            // Если не удалось обновить токен - разлогиниваем
            logout();
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <AdminAuthContext.Provider value={{ isAuthenticated, admin, login, logout, loading }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (context === undefined) {
    throw new Error('useAdminAuth must be used within an AdminAuthProvider');
  }
  return context;
}; 