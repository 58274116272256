import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Button,
  LinearProgress,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Speed as SpeedIcon,
  Analytics as AnalyticsIcon,
  Group as GroupIcon,
  ContentCopy as ContentCopyIcon,
  DataUsage as DataUsageIcon,
  AttachMoney as AttachMoneyIcon,
  Timeline as TimelineIcon,
  PieChart as PieChartIcon,
  Public,
  Lock,
  People as PeopleIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { Product, ProductStatus, ProductUsage, ProductFeature } from '../../../types/products';
import api from '../../../services/api';
import ProductDialog from '../../../components/ProductDialog/ProductDialog';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { PRODUCT_ICONS } from '../../../constants/productIcons';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const MetricBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(220, 251, 1, 0.05)',
  borderRadius: '12px',
  height: '100%',
  minHeight: '90px',
  '&:hover': {
    backgroundColor: 'rgba(220, 251, 1, 0.08)',
    transform: 'translateY(-2px)',
    transition: 'all 0.3s ease-in-out',
  },
}));

const StatusChip = styled(Chip)<{ status: ProductStatus }>(({ theme, status }) => ({
  borderRadius: '8px',
  fontWeight: 500,
  ...(status === 'active' && {
    backgroundColor: 'rgba(220, 251, 1, 0.1)',
    color: '#DCFB01',
  }),
  ...(status === 'beta' && {
    backgroundColor: 'rgba(255, 171, 0, 0.1)',
    color: '#FFAB00',
  }),
  ...(status === 'deprecated' && {
    backgroundColor: 'rgba(121, 62, 238, 0.1)',
    color: '#793EEE',
  }),
  ...(status === 'soon' && {
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
    color: '#2196F3',
  }),
}));

const getNextCloneName = (name: string): string => {
  const regex = /^(.*?)(\s*\((\d+)\))?$/;
  const match = name.match(regex);
  
  if (!match) return `${name} (2)`;
  
  const baseName = match[1];
  const currentNumber = match[3] ? parseInt(match[3]) : 1;
  
  return `${baseName} (${currentNumber + 1})`;
};

// Добавим функцию проверки наличия активных метрик
const hasActiveMetrics = (metricsSettings: Record<string, any> | undefined) => {
  if (!metricsSettings) return false;
  return Object.values(metricsSettings).some(setting => setting.enabled);
};

interface IconType {
  type: string;
  icon: React.ComponentType;
  label: string;
}

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '200px',
  height: '150px',
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const IconOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  left: 8,
  padding: theme.spacing(1),
  borderRadius: '8px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(4px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
}));

const ProductDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [usage, setUsage] = useState<ProductUsage | null>(null);
  const [usageLoading, setUsageLoading] = useState(true);

  useEffect(() => {
    if (id) {
      Promise.all([
        fetchProductDetails(),
        fetchProductUsage()
      ]).finally(() => {
        setLoading(false);
        setUsageLoading(false);
      });
    }
  }, [id]);

  const fetchProductDetails = async () => {
    if (!id) return;
    try {
      console.log('🔍 Fetching product details:', { productId: id });
      
      const response = await api.get(`/admin/products/${id}`);
      console.log('✅ Product details loaded:', {
        productId: id,
        name: response.data.name
      });
      
      setProduct(response.data);
      setError(null);
    } catch (err) {
      console.error('❌ Error fetching product details:', err);
      setError('Failed to load product details');
    } finally {
      setLoading(false);
    }
  };

  const fetchProductUsage = async () => {
    if (!id) return;
    try {
      console.log('📊 Fetching product usage:', { productId: id });
      
      const response = await api.get(`/admin/products/${id}/usage`);
      console.log('✅ Product usage loaded:', {
        productId: id,
        totalRequests: response.data.totalRequests
      });
      
      setUsage(response.data);
    } catch (err) {
      console.error('❌ Error fetching product usage:', err);
    }
  };

  const handleSave = async (updatedProduct: Partial<Product>) => {
    if (!product?.id) return;
    try {
      await api.put(`/admin/products/${product.id}`, updatedProduct);
      await fetchProductDetails();
      setDialogOpen(false);
    } catch (error) {
      console.error('Error updating product:', error);
      setError('Failed to update product');
    }
  };

  const getFeatures = (product: Product | null) => {
    if (!product) return [];
    
    try {
      if (typeof product.features === 'string') {
        return JSON.parse(product.features);
      }
      if (Array.isArray(product.features)) {
        return product.features;
      }
      return [];
    } catch (e) {
      console.error('Error parsing features:', e);
      return [];
    }
  };

  const getMetricIcon = (metricCode: string) => {
    const type = product?.type_metrics_config?.[metricCode]?.type || 'default';
    switch (type) {
      case 'time':
        return <TimelineIcon sx={{ color: '#DCFB01' }} />;
      case 'number':
        return <DataUsageIcon sx={{ color: '#DCFB01' }} />;
      case 'percentage':
        return <PieChartIcon sx={{ color: '#DCFB01' }} />;
      default:
        return <SpeedIcon sx={{ color: '#DCFB01' }} />;
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (error || !product) {
    return (
      <Typography color="error">
        {error || 'Product not found'}
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/products')}
          sx={{ mb: 2, color: '#DCFB01' }}
        >
          Back to Products
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledPaper>
            <Box sx={{ display: 'flex', gap: 3, mb: 4 }}>
              <Box>
                <ImageContainer>
                  {product.icon_type && (
                    <IconOverlay>
                      {React.createElement(
                        PRODUCT_ICONS.find((icon: IconType) => icon.type === product.icon_type)?.icon || 'div',
                        { sx: { color: '#DCFB01', fontSize: 24 } }
                      )}
                    </IconOverlay>
                  )}
                  {product.image_url ? (
                    <Box
                      component="img"
                      src={product.image_url}
                      alt={product.name}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  ) : (
                    <Box sx={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <Typography variant="body2" color="text.secondary">
                        No image
                      </Typography>
                    </Box>
                  )}
                </ImageContainer>
              </Box>

              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                  <Box>
                    <Typography variant="h4" sx={{ color: '#DCFB01', mb: 1 }}>
                      {product.name}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                      <StatusChip label={product.status.toUpperCase()} status={product.status} />
                      <Chip
                        icon={product.visibility === 'public' ? <Public /> : <Lock />}
                        label={product.visibility}
                        sx={{
                          backgroundColor: 'rgba(255, 255, 255, 0.05)',
                          color: 'white',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate('/products', { 
                          state: { 
                            cloneProduct: {
                              ...product,
                              name: getNextCloneName(product.name),
                            }
                          }
                        });
                      }}
                      sx={{ 
                        bgcolor: 'rgba(220, 251, 1, 0.1)',
                        color: '#DCFB01',
                        '&:hover': {
                          bgcolor: 'rgba(220, 251, 1, 0.2)',
                        }
                      }}
                    >
                      Clone
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<EditIcon />}
                      onClick={() => setDialogOpen(true)}
                      sx={{ 
                        bgcolor: '#DCFB01',
                        color: '#000',
                        '&:hover': {
                          bgcolor: 'rgba(220, 251, 1, 0.8)',
                        }
                      }}
                    >
                      Edit Product
                    </Button>
                  </Box>
                </Box>

                <Box>
                  <Typography variant="h6" sx={{ color: '#DCFB01', mb: 2 }}>
                    Features
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {getFeatures(product).map((feature: ProductFeature) => (
                      <Chip
                        key={feature.id}
                        label={feature.name}
                        sx={{
                          backgroundColor: feature.isEnabled 
                            ? 'rgba(220, 251, 1, 0.1)' 
                            : 'rgba(255, 255, 255, 0.05)',
                          color: feature.isEnabled ? '#DCFB01' : 'white',
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Typography variant="h6" sx={{ mb: 3, color: '#DCFB01' }}>
              Overview
            </Typography>
            <Typography sx={{ mb: 3 }}>
              {product.description}
            </Typography>
            <Box sx={{ mt: 'auto' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <MetricBox>
                    <Box sx={{ mr: 2 }}>
                      <SpeedIcon sx={{ color: '#DCFB01' }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        Version
                      </Typography>
                      <Typography variant="h6">
                        {product.version}
                      </Typography>
                    </Box>
                  </MetricBox>
                </Grid>
                {Object.entries(product.metricsSettings || {})
                  .filter(([_, settings]) => settings.enabled)
                  .map(([code, settings]) => (
                    <Grid item xs={12} md={4} key={code}>
                      <MetricBox>
                        <Box sx={{ mr: 2 }}>
                          {getMetricIcon(code)}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            {code.split('_').map(word => 
                              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                            ).join(' ')}
                          </Typography>
                          <Typography variant="h6">
                            {settings.limit} {product.type_metrics_config?.[code]?.unit || ''}
                          </Typography>
                          {settings.alert_threshold && (
                            <Typography variant="caption" color="text.secondary">
                              Alert at {settings.alert_threshold}%
                            </Typography>
                          )}
                        </Box>
                      </MetricBox>
                    </Grid>
                  ))}
                <Grid item xs={12} md={4}>
                  <MetricBox>
                    <Box sx={{ mr: 2 }}>
                      {product.visibility === 'public' ? (
                        <Public sx={{ color: '#DCFB01' }} />
                      ) : (
                        <Lock sx={{ color: '#DCFB01' }} />
                      )}
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Visibility
                      </Typography>
                      <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                        {product.visibility}
                      </Typography>
                      {product.visibility === 'private' && (
                        <Typography variant="caption" color="text.secondary">
                          {product.allowedTeams?.length || 0} teams have access
                        </Typography>
                      )}
                    </Box>
                  </MetricBox>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>

        {product.visibility === 'private' && (
          <Grid item xs={12}>
            <StyledPaper>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <PeopleIcon sx={{ color: '#DCFB01' }} />
                <Typography variant="h6" sx={{ color: '#DCFB01' }}>
                  Allowed Teams
                </Typography>
              </Box>
              
              {product.allowedTeams && product.allowedTeams.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Team Name</TableCell>
                        <TableCell>Granted At</TableCell>
                        <TableCell>Granted By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {product.allowedTeams.map((team) => (
                        <TableRow key={team.teamId}>
                          <TableCell>{team.teamName}</TableCell>
                          <TableCell>
                            {new Date(team.grantedAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{team.grantedBy}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography color="text.secondary">
                  No teams have access to this product
                </Typography>
              )}
            </StyledPaper>
          </Grid>
        )}
      </Grid>

      <Box sx={{ mt: 4 }}>
        <StyledPaper>
          <Typography variant="h6" sx={{ mb: 3, color: '#DCFB01' }}>
            Integration
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Webhook URL
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2,
              p: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '12px',
              border: '1px solid rgba(255, 255, 255, 0.05)'
            }}>
              <Typography 
                sx={{ 
                  flex: 1,
                  fontFamily: 'monospace',
                  fontSize: '0.875rem',
                  color: '#DCFB01'
                }}
              >
                {product.webhook_url}
              </Typography>
              <IconButton
                onClick={() => navigator.clipboard.writeText(product.webhook_url)}
                sx={{ 
                  color: 'rgba(220, 251, 1, 0.7)',
                  '&:hover': {
                    color: '#DCFB01'
                  }
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Box>
        </StyledPaper>
      </Box>

      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 3, color: '#DCFB01' }}>
              Usage Statistics
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <MetricBox>
                  <Box sx={{ mr: 2, color: '#DCFB01' }}>
                    <GroupIcon />
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Active Teams
                    </Typography>
                    <Typography variant="h6">
                      {usage?.totalTeams || 0}
                    </Typography>
                  </Box>
                </MetricBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricBox>
                  <Box sx={{ mr: 2, color: '#DCFB01' }}>
                    <SpeedIcon />
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Total Requests
                    </Typography>
                    <Typography variant="h6">
                      {usage?.totalRequests.toLocaleString() || 0}
                    </Typography>
                  </Box>
                </MetricBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricBox>
                  <Box sx={{ mr: 2, color: '#DCFB01' }}>
                    <DataUsageIcon />
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Total Tokens
                    </Typography>
                    <Typography variant="h6">
                      {usage?.totalTokens.toLocaleString() || 0}
                    </Typography>
                  </Box>
                </MetricBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricBox>
                  <Box sx={{ mr: 2, color: '#DCFB01' }}>
                    <AttachMoneyIcon />
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Total Spend
                    </Typography>
                    <Typography variant="h6">
                      ${usage?.totalSpend.toFixed(2) || '0.00'}
                    </Typography>
                  </Box>
                </MetricBox>
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, height: 300 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, color: '#DCFB01' }}>
                Daily Usage
              </Typography>
              <ResponsiveContainer>
                <LineChart data={usage?.dailyStats || []}>
                  <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                  <XAxis 
                    dataKey="date" 
                    stroke="#ffffff"
                    tick={{ fill: '#ffffff' }}
                  />
                  <YAxis 
                    stroke="#ffffff"
                    tick={{ fill: '#ffffff' }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: 'rgba(30, 30, 30, 0.95)',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '8px',
                    }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="requests" 
                    stroke="#DCFB01" 
                    strokeWidth={2}
                    dot={{ fill: '#DCFB01' }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1" sx={{ color: '#DCFB01' }}>
                  Active Teams
                </Typography>
                {product.visibility === 'private' && (
                  <Chip
                    label={`${product.allowedTeams?.length || 0} approved teams`}
                    sx={{
                      backgroundColor: 'rgba(220, 251, 1, 0.1)',
                      color: '#DCFB01',
                    }}
                  />
                )}
              </Box>
              
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Team</TableCell>
                      <TableCell align="right">Requests</TableCell>
                      <TableCell align="right">Tokens</TableCell>
                      <TableCell align="right">Spend</TableCell>
                      <TableCell align="right">Last Used</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usage?.activeTeams.map((team) => {
                      const isApproved = product.allowedTeams?.some(
                        allowedTeam => allowedTeam.teamId === team.teamId
                      );
                      
                      return (
                        <TableRow key={team.teamId}>
                          <TableCell>{team.teamName}</TableCell>
                          <TableCell align="right">{team.requests.toLocaleString()}</TableCell>
                          <TableCell align="right">{team.tokens.toLocaleString()}</TableCell>
                          <TableCell align="right">${team.spend.toFixed(2)}</TableCell>
                          <TableCell align="right">
                            {new Date(team.lastUsed).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="right">
                            {product.visibility === 'private' && (
                              <Chip
                                size="small"
                                label={isApproved ? 'Approved' : 'Not Approved'}
                                sx={{
                                  backgroundColor: isApproved 
                                    ? 'rgba(220, 251, 1, 0.1)' 
                                    : 'rgba(255, 255, 255, 0.05)',
                                  color: isApproved ? '#DCFB01' : 'white',
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* Показываем одобренные команды, которые еще не активны */}
                    {product.visibility === 'private' && product.allowedTeams?.map(team => {
                      const isActive = usage?.activeTeams.some(
                        activeTeam => activeTeam.teamId === team.teamId
                      );
                      
                      if (!isActive) {
                        return (
                          <TableRow key={team.teamId}>
                            <TableCell>{team.teamName}</TableCell>
                            <TableCell align="right">0</TableCell>
                            <TableCell align="right">0</TableCell>
                            <TableCell align="right">$0.00</TableCell>
                            <TableCell align="right">Never</TableCell>
                            <TableCell align="right">
                              <Chip
                                size="small"
                                label="Approved (Inactive)"
                                sx={{
                                  backgroundColor: 'rgba(220, 251, 1, 0.1)',
                                  color: '#DCFB01',
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>

      <ProductDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={handleSave}
        product={product}
      />
    </Box>
  );
};

export default ProductDetails; 