import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  TextFields,
  CheckBox,
  RadioButtonChecked,
  List,
  Image as ImageIcon,
  Chat,
  Timeline,
  Code,
  TableChart,
  Tab,
  ViewDay,
  SmartButton,
  DateRange,
  AccessTime,
  Upload,
  Edit,
  Storage,
  ViewModule,
  DragIndicator,
  Layers,
  Close as CloseIcon,
  PlayArrow as PlayArrowIcon,
  Mic as MicIcon,
  GraphicEq as WaveformIcon,
  RecordVoiceOver as VoiceIcon,
  Message as MessageIcon,
  Psychology as AIIcon,
  SupportAgent as SupportIcon,
  Call as CallIcon,
  Assessment as AssessmentIcon,
  Person as PersonIcon,
  PhotoLibrary as GalleryIcon,
  Brush as BrushIcon,
  Tune as TuneIcon,
  Settings as SettingsIcon,
  MenuBook as MenuBookIcon,
  SignalCellularAlt as SignalCellularAltIcon,
  Description as DescriptionIcon,
  GridOn as GridOnIcon,
  ViewQuilt as ViewQuiltIcon,
} from '@mui/icons-material';
import { ComponentType } from '../../types/products';
import { ComponentRenderer } from '../InterfaceComponents/ComponentRenderer';
import { ComponentConfig } from '../../types/interface-components';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
  }
}));

const ComponentCard = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  border: '1px solid rgba(220, 251, 1, 0.2)',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: 'rgba(220, 251, 1, 0.05)',
    transform: 'translateY(-2px)',
  }
}));

const PreviewBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 20,
  height: 'calc(100vh - 200px)',
  overflowY: 'auto',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '12px',
  border: '1px solid rgba(220, 251, 1, 0.1)',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(220, 251, 1, 0.3)',
    borderRadius: '3px',
  },
}));

const ComponentsContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 200px)',
  overflowY: 'auto',
  paddingRight: '16px',
  marginRight: '-16px',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(220, 251, 1, 0.3)',
    borderRadius: '3px',
  },
}));

const COMPONENT_TYPES = [
  {
    category: 'Basic Inputs',
    components: [
      {
        type: 'text-input' as ComponentType,
        name: 'Text Input',
        icon: <TextFields />,
        description: 'Single line text input field'
      },
      {
        type: 'textarea' as ComponentType,
        name: 'Text Area',
        icon: <Edit />,
        description: 'Multi-line text input'
      },
      {
        type: 'number-input' as ComponentType,
        name: 'Number Input',
        icon: <Timeline />,
        description: 'Numeric input with validation'
      },
      {
        type: 'checkbox' as ComponentType,
        name: 'Checkbox',
        icon: <CheckBox />,
        description: 'Boolean selection input'
      },
      {
        type: 'radio-group' as ComponentType,
        name: 'Radio Group',
        icon: <RadioButtonChecked />,
        description: 'Single selection from options'
      },
      {
        type: 'select' as ComponentType,
        name: 'Dropdown',
        icon: <List />,
        description: 'Dropdown selection menu'
      }
    ]
  },
  {
    category: 'Advanced Inputs',
    components: [
      {
        type: 'slider' as ComponentType,
        name: 'Slider',
        icon: <DragIndicator />,
        description: 'Range selection slider'
      },
      {
        type: 'date-picker' as ComponentType,
        name: 'Date Picker',
        icon: <DateRange />,
        description: 'Date selection calendar'
      },
      {
        type: 'time-picker' as ComponentType,
        name: 'Time Picker',
        icon: <AccessTime />,
        description: 'Time selection input'
      },
      {
        type: 'file-upload' as ComponentType,
        name: 'File Upload',
        icon: <Upload />,
        description: 'File upload with preview'
      },
      {
        type: 'image-upload' as ComponentType,
        name: 'Image Upload',
        icon: <ImageIcon />,
        description: 'Image upload with preview'
      }
    ]
  },
  {
    category: 'Complex Components',
    components: [
      {
        type: 'chat-interface' as ComponentType,
        name: 'Chat Interface',
        icon: <Chat />,
        description: 'Interactive chat component'
      },
      {
        type: 'code-editor' as ComponentType,
        name: 'Code Editor',
        icon: <Code />,
        description: 'Code editing with syntax highlighting'
      },
      {
        type: 'markdown-editor' as ComponentType,
        name: 'Markdown Editor',
        icon: <Edit />,
        description: 'Rich text markdown editor'
      },
      {
        type: 'json-editor' as ComponentType,
        name: 'JSON Editor',
        icon: <Storage />,
        description: 'JSON structure editor'
      }
    ]
  },
  {
    category: 'Data Display',
    components: [
      {
        type: 'chart' as ComponentType,
        name: 'Chart',
        icon: <Timeline />,
        description: 'Data visualization component'
      },
      {
        type: 'table' as ComponentType,
        name: 'Table',
        icon: <TableChart />,
        description: 'Data grid with sorting and filtering'
      }
    ]
  },
  {
    category: 'Layout',
    components: [
      {
        type: 'tabs' as ComponentType,
        name: 'Tabs',
        icon: <Tab />,
        description: 'Tabbed content container'
      },
      {
        type: 'accordion' as ComponentType,
        name: 'Accordion',
        icon: <ViewDay />,
        description: 'Collapsible content sections'
      },
      {
        type: 'grid' as ComponentType,
        name: 'Grid',
        icon: <GridOnIcon />,
        description: 'Grid layout container'
      },
      {
        type: 'flex-container' as ComponentType,
        name: 'Flex Container',
        icon: <ViewQuiltIcon />,
        description: 'Flexible layout container'
      },
      {
        type: 'card' as ComponentType,
        name: 'Card',
        icon: <ViewModule />,
        description: 'Content card container'
      }
    ]
  },
  {
    category: 'Actions',
    components: [
      {
        type: 'button' as ComponentType,
        name: 'Button',
        icon: <SmartButton />,
        description: 'Clickable button element'
      },
      {
        type: 'divider' as ComponentType,
        name: 'Divider',
        icon: <DragIndicator />,
        description: 'Visual separator'
      },
      {
        type: 'spacer' as ComponentType,
        name: 'Spacer',
        icon: <Layers />,
        description: 'Empty space for layout'
      }
    ]
  },
  {
    category: 'Audio Components',
    components: [
      {
        type: 'audio-player' as ComponentType,
        name: 'Audio Player',
        icon: <PlayArrowIcon />,
        description: 'Audio playback with controls'
      },
      {
        type: 'audio-recorder' as ComponentType,
        name: 'Audio Recorder',
        icon: <MicIcon />,
        description: 'Record audio with visualization'
      },
      {
        type: 'audio-visualizer' as ComponentType,
        name: 'Audio Visualizer',
        icon: <WaveformIcon />,
        description: 'Real-time audio visualization'
      },
      {
        type: 'voice-input' as ComponentType,
        name: 'Voice Input',
        icon: <VoiceIcon />,
        description: 'Voice-to-text input field'
      }
    ]
  },
  {
    category: 'AI Text Components',
    components: [
      {
        type: 'prompt-builder' as ComponentType,
        name: 'Prompt Builder',
        icon: <AIIcon />,
        description: 'Advanced prompt construction'
      },
      {
        type: 'response-viewer' as ComponentType,
        name: 'Response Viewer',
        icon: <MessageIcon />,
        description: 'AI response display with formatting'
      },
      {
        type: 'model-selector' as ComponentType,
        name: 'Model Selector',
        icon: <TuneIcon />,
        description: 'AI model selection and parameters'
      }
    ]
  },
  {
    category: 'Support Components',
    components: [
      {
        type: 'ticket-form' as ComponentType,
        name: 'Ticket Form',
        icon: <SupportIcon />,
        description: 'Support ticket creation form'
      },
      {
        type: 'knowledge-base' as ComponentType,
        name: 'Knowledge Base',
        icon: <MenuBookIcon />,
        description: 'Searchable knowledge base'
      }
    ]
  },
  {
    category: 'Call Center Components',
    components: [
      {
        type: 'call-controls' as ComponentType,
        name: 'Call Controls',
        icon: <CallIcon />,
        description: 'Call management interface'
      },
      {
        type: 'call-quality' as ComponentType,
        name: 'Call Quality',
        icon: <SignalCellularAltIcon />,
        description: 'Call quality metrics'
      },
      {
        type: 'call-metrics' as ComponentType,
        name: 'Call Metrics',
        icon: <AssessmentIcon />,
        description: 'Real-time call statistics'
      }
    ]
  },
  {
    category: 'HR Components',
    components: [
      {
        type: 'resume-parser' as ComponentType,
        name: 'Resume Parser',
        icon: <DescriptionIcon />,
        description: 'AI-powered resume analysis'
      },
      {
        type: 'candidate-profile' as ComponentType,
        name: 'Candidate Profile',
        icon: <PersonIcon />,
        description: 'Candidate information display'
      },
      {
        type: 'skill-matrix' as ComponentType,
        name: 'Skill Matrix',
        icon: <GridOnIcon />,
        description: 'Skills assessment grid'
      }
    ]
  },
  {
    category: 'Image Generation',
    components: [
      {
        type: 'image-gallery' as ComponentType,
        name: 'Image Gallery',
        icon: <GalleryIcon />,
        description: 'Generated images gallery'
      },
      {
        type: 'style-controls' as ComponentType,
        name: 'Style Controls',
        icon: <BrushIcon />,
        description: 'Image style parameters'
      },
      {
        type: 'prompt-image-builder' as ComponentType,
        name: 'Image Prompt Builder',
        icon: <ImageIcon />,
        description: 'Visual prompt construction'
      }
    ]
  },
  {
    category: 'Settings',
    components: [
      {
        type: 'settings-panel' as ComponentType,
        name: 'Settings Panel',
        icon: <SettingsIcon />,
        description: 'Product configuration panel'
      }
    ]
  }
];

interface ComponentPickerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (componentType: ComponentType) => void;
}

const ComponentPicker: React.FC<ComponentPickerProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [selectedComponent, setSelectedComponent] = useState<{
    type: ComponentType;
    defaultProps: any;
  } | null>(null);

  const handleComponentHover = (component: typeof COMPONENT_TYPES[0]['components'][0]) => {
    setSelectedComponent({
      type: component.type,
      defaultProps: getDefaultProps(component.type)
    });
  };

  const getDefaultProps = (type: ComponentType) => {
    switch (type) {
      case 'text-input':
        return {
          label: 'Sample Text Input',
          placeholder: 'Enter text...',
          required: false,
        };
      case 'number-input':
        return {
          label: 'Sample Number',
          min: 0,
          max: 100,
          step: 1,
        };
      case 'checkbox':
        return {
          label: 'Sample Checkbox',
          defaultChecked: false,
        };
      case 'radio-group':
        return {
          label: 'Sample Radio Group',
          options: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
          ],
        };
      case 'select':
        return {
          label: 'Sample Select',
          options: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
          ],
        };
      case 'slider':
        return {
          label: 'Sample Slider',
          min: 0,
          max: 100,
          step: 1,
          defaultValue: 50,
        };
      case 'tabs':
        return {
          type: 'tabs' as const,
          tabs: [
            {
              id: `tab-${Date.now()}-1`,
              label: 'Tab 1',
              content: []
            },
            {
              id: `tab-${Date.now()}-2`,
              label: 'Tab 2',
              content: []
            }
          ],
          orientation: 'horizontal',
          variant: 'standard',
          centered: false,
        };
      case 'accordion':
        return {
          type: 'accordion' as const,
          sections: [
            {
              id: `section-${Date.now()}-1`,
              title: 'Section 1',
              subtitle: 'First section',
              content: [],
              expanded: true
            },
            {
              id: `section-${Date.now()}-2`,
              title: 'Section 2',
              subtitle: 'Second section',
              content: [],
              expanded: false
            }
          ],
          allowMultiple: true,
          defaultExpanded: [`section-${Date.now()}-1`],
        };
      case 'grid':
        return {
          type: 'grid' as const,
          spacing: 2,
          items: [
            {
              id: `grid-item-${Date.now()}-1`,
              content: {
                id: `content-${Date.now()}-1`,
                type: 'text-input',
                props: {
                  type: 'text-input',
                  label: 'Grid Item 1',
                  placeholder: 'Enter text...'
                },
                order: 0
              },
              gridProps: {
                xs: 12,
                sm: 6,
                md: 4
              }
            },
            {
              id: `grid-item-${Date.now()}-2`,
              content: {
                id: `content-${Date.now()}-2`,
                type: 'text-input',
                props: {
                  type: 'text-input',
                  label: 'Grid Item 2',
                  placeholder: 'Enter text...'
                },
                order: 1
              },
              gridProps: {
                xs: 12,
                sm: 6,
                md: 4
              }
            }
          ],
          justifyContent: 'flex-start',
          alignItems: 'stretch'
        };
      case 'flex-container':
        return {
          type: 'flex-container' as const,
          direction: 'row',
          wrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          spacing: 2,
          items: [
            {
              id: `flex-item-${Date.now()}-1`,
              content: {
                id: `content-${Date.now()}-1`,
                type: 'text-input',
                props: {
                  label: 'Flex Item 1',
                  placeholder: 'Enter text...'
                }
              },
              flex: '1',
              order: 0
            },
            {
              id: `flex-item-${Date.now()}-2`,
              content: {
                id: `content-${Date.now()}-2`,
                type: 'text-input',
                props: {
                  label: 'Flex Item 2',
                  placeholder: 'Enter text...'
                }
              },
              flex: '1',
              order: 1
            }
          ]
        };
      // ... добавьте другие типы по мере необходимости
      default:
        return {};
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: '#DCFB01' }}>
            Select Component Type
          </Typography>
          <IconButton onClick={onClose} sx={{ color: '#DCFB01' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {/* Категории компонентов */}
          <Grid item xs={12} md={8}>
            <ComponentsContainer>
              <Grid container spacing={2}>
                {COMPONENT_TYPES.map((category) => (
                  <Grid item xs={12} key={category.category}>
                    <Typography variant="h6" sx={{ color: '#DCFB01', mb: 2 }}>
                      {category.category}
                    </Typography>
                    <Grid container spacing={2}>
                      {category.components.map((component) => (
                        <Grid item xs={12} sm={6} md={4} key={component.type}>
                          <ComponentCard
                            onMouseEnter={() => handleComponentHover(component)}
                            onClick={() => {
                              onSelect(component.type);
                              onClose();
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <Box sx={{ 
                                mr: 1,
                                color: '#DCFB01',
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                                {component.icon}
                              </Box>
                              <Typography variant="subtitle1">
                                {component.name}
                              </Typography>
                            </Box>
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ flex: 1 }}
                            >
                              {component.description}
                            </Typography>
                          </ComponentCard>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </ComponentsContainer>
          </Grid>

          {/* Предпрос��отр компонента */}
          <Grid item xs={12} md={4}>
            <PreviewBox>
              <Typography variant="h6" sx={{ color: '#DCFB01', mb: 2 }}>
                Preview
              </Typography>
              {selectedComponent ? (
                <ComponentRenderer
                  config={{
                    id: 'preview',
                    type: selectedComponent.type,
                    ...selectedComponent.defaultProps,
                  } as ComponentConfig}
                  preview={true}
                />
              ) : (
                <Typography color="text.secondary" align="center">
                  Hover over a component to see preview
                </Typography>
              )}
            </PreviewBox>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default ComponentPicker; 