import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Chip,
  CircularProgress,
} from '@mui/material';
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ProductType } from '../../types/products';
import { productsApi } from '../../services/productsApi';
import Notification from '../Notification/Notification';
import { InterfaceComponent, InterfaceConfig, InterfaceTemplate } from '../../types/interface-components';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
  }
}));

const TemplateItem = styled(ListItem)(({ theme }) => ({
  border: '1px solid rgba(220, 251, 1, 0.2)',
  borderRadius: '12px',
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'rgba(220, 251, 1, 0.05)',
  }
}));

interface InterfaceTemplatesProps {
  open: boolean;
  onClose: () => void;
  onApplyTemplate: (template: InterfaceTemplate) => Promise<void>;
  onSaveAsTemplate: (name: string, description: string) => Promise<void>;
  currentConfig: InterfaceConfig;
  productType: ProductType;
}

const InterfaceTemplates: React.FC<InterfaceTemplatesProps> = ({
  open,
  onClose,
  onApplyTemplate,
  onSaveAsTemplate,
  currentConfig,
  productType
}) => {
  const [templates, setTemplates] = useState<InterfaceTemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateDescription, setNewTemplateDescription] = useState('');
  const [saveMode, setSaveMode] = useState(false);

  useEffect(() => {
    if (open) {
      loadTemplates();
    }
  }, [open, productType]);

  const loadTemplates = async () => {
    try {
      setLoading(true);
      const response = await productsApi.getInterfaceTemplates(productType);
      setTemplates(response.data);
    } catch (error) {
      console.error('Error loading templates:', error);
      showNotification('Failed to load templates', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTemplate = async () => {
    if (!newTemplateName) return;

    try {
      await onSaveAsTemplate(newTemplateName, newTemplateDescription);
      await loadTemplates();
      setNewTemplateName('');
      setNewTemplateDescription('');
      setSaveMode(false);
      showNotification('Template saved successfully', 'success');
    } catch (error) {
      console.error('Error saving template:', error);
      showNotification('Failed to save template', 'error');
    }
  };

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      await productsApi.deleteInterfaceTemplate(templateId);
      await loadTemplates();
      showNotification('Template deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting template:', error);
      showNotification('Failed to delete template', 'error');
    }
  };

  const showNotification = (message: string, severity: 'success' | 'error') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: '#DCFB01' }}>
            Interface Templates
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setSaveMode(true)}
            sx={{
              color: '#DCFB01',
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.1)',
              }
            }}
          >
            Save Current as Template
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress sx={{ color: '#DCFB01' }} />
          </Box>
        ) : saveMode ? (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Template Name"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                }
              }}
            />
            <TextField
              fullWidth
              label="Description"
              value={newTemplateDescription}
              onChange={(e) => setNewTemplateDescription(e.target.value)}
              multiline
              rows={3}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                }
              }}
            />
            <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                onClick={handleSaveTemplate}
                sx={{
                  bgcolor: '#DCFB01',
                  color: '#000',
                  '&:hover': {
                    bgcolor: 'rgba(220, 251, 1, 0.8)',
                  }
                }}
              >
                Save Template
              </Button>
              <Button
                onClick={() => setSaveMode(false)}
                sx={{
                  color: '#DCFB01',
                  '&:hover': {
                    backgroundColor: 'rgba(220, 251, 1, 0.1)',
                  }
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <List>
            {templates.length === 0 ? (
              <Box sx={{ textAlign: 'center', p: 3, color: 'text.secondary' }}>
                <Typography>
                  No templates available for this product type.
                </Typography>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => setSaveMode(true)}
                  sx={{
                    mt: 2,
                    color: '#DCFB01',
                    '&:hover': {
                      backgroundColor: 'rgba(220, 251, 1, 0.1)',
                    }
                  }}
                >
                  Create First Template
                </Button>
              </Box>
            ) : (
              templates.map((template) => (
                <TemplateItem key={template.id}>
                  <ListItemText
                    primary={template.name}
                    secondary={
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          {template.description}
                        </Typography>
                        <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                          <Chip
                            size="small"
                            label={template.productType}
                            sx={{
                              backgroundColor: 'rgba(220, 251, 1, 0.1)',
                              color: '#DCFB01',
                            }}
                          />
                          <Chip
                            size="small"
                            label={`${template.components.length} components`}
                            sx={{
                              backgroundColor: 'rgba(220, 251, 1, 0.1)',
                              color: '#DCFB01',
                            }}
                          />
                        </Box>
                      </Box>
                    }
                  />
                  <Box>
                    <IconButton
                      onClick={() => onApplyTemplate(template)}
                      sx={{ color: '#DCFB01' }}
                    >
                      <CopyIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteTemplate(template.id)}
                      sx={{ color: '#DCFB01' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TemplateItem>
              ))
            )}
          </List>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#DCFB01',
            '&:hover': {
              backgroundColor: 'rgba(220, 251, 1, 0.1)',
            }
          }}
        >
          Close
        </Button>
      </DialogActions>

      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </StyledDialog>
  );
};

export default InterfaceTemplates; 