import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, CircularProgress, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  QueryStats,
  AccessTime,
  DataUsage,
  Speed,
  Receipt,
  AutoAwesome,
  Psychology,
  Biotech
} from '@mui/icons-material';
import { keyframes } from '@mui/system';
import api from '../../services/api';
import { Theme } from '@mui/material/styles';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';

const COLORS = {
  primary: '#DCFB01',    // неоново-желтый
  secondary: '#793EEE',  // фиолетовый
  background: '#121212', // темный фон
  cardBg: 'rgba(32, 32, 32, 0.6)',
  iconBg: 'rgba(220, 251, 1, 0.1)',
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 5px ${COLORS.primary}20;
  }
  50% {
    box-shadow: 0 0 20px ${COLORS.primary}40;
  }
  100% {
    box-shadow: 0 0 5px ${COLORS.primary}20;
  }
`;

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: '24px',
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  boxShadow: 'none',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `
      0 0 5px rgba(220, 251, 1, 0.2),
      0 0 10px rgba(220, 251, 1, 0.2),
      0 0 15px rgba(220, 251, 1, 0.1)
    `,
  }
}));

const IconWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'rgba(220, 251, 1, 0.1)',
  borderRadius: '12px',
  padding: '12px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  marginBottom: '16px',
  '& svg': {
    fontSize: 24,
    color: '#DCFB01',
  },
  '.StyledPaper:hover &': {
    backgroundColor: 'rgba(220, 251, 1, 0.15)',
    transform: 'scale(1.05)',
  }
}));

interface StatsCard {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  color: string;
  unit?: string;
  subtitle?: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}

interface DashboardStats {
  totalUsers: number;
  totalTeams: number;
  pendingRequests: number;
  approvedRequests: number;
  rejectedRequests: number;
  totalUsage: number;
  weeklyUsage: {
    spend: string;
    requests: number;
  };
  monthlyUsage: {
    spend: string;
    requests: number;
  };
  currentMonthUsage: {
    requests: number;
    tokens: number;
    spend: string;
  };
  recentActivity: Array<{
    date: string;
    requests: number;
  }>;
}

interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode;
  trend?: string;
  actionButton?: React.ReactNode;
  unit?: string;
}

const StatCard = ({ title, value, icon, trend, actionButton, unit }: StatCardProps) => (
  <StyledPaper>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 2,
      mb: 2 
    }}>
      <Box
        className="icon-wrapper"
        sx={{
          backgroundColor: 'rgba(220, 251, 1, 0.1)',
          borderRadius: '12px',
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      >
        {React.cloneElement(icon as React.ReactElement, {
          sx: { fontSize: 24, color: COLORS.primary }
        })}
      </Box>
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: '1rem',
          fontWeight: 500
        }}
      >
        {title}
      </Typography>
    </Box>

    <Box sx={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      mt: 2
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'baseline',
        gap: 1
      }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            color: COLORS.primary,
            fontSize: '2.5rem',
            textAlign: 'left',
            lineHeight: 1
          }}
        >
          {typeof value === 'number' ? value.toLocaleString() : value}
        </Typography>
        {unit && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.5)',
              fontSize: '0.875rem',
              mb: 0.5
            }}
          >
            {unit}
          </Typography>
        )}
      </Box>
      {trend && (
        <Typography 
          variant="body2" 
          sx={{ 
            color: COLORS.secondary,
            fontSize: '0.875rem',
            textAlign: 'left',
            mb: actionButton ? 2 : 0
          }}
        >
          {trend}
        </Typography>
      )}
    </Box>

    {actionButton && (
      <Box sx={{ 
        mt: 'auto',
        pt: 2
      }}>
        <Button
          variant="outlined"
          fullWidth
          sx={{
            color: COLORS.primary,
            borderColor: 'rgba(220, 251, 1, 0.3)',
            borderRadius: '10px',
            textTransform: 'none',
            padding: '8px',
            fontSize: '0.875rem',
            fontWeight: 500,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              borderColor: COLORS.primary,
              backgroundColor: 'rgba(220, 251, 1, 0.1)',
            }
          }}
        >
          {actionButton}
        </Button>
      </Box>
    )}
  </StyledPaper>
);

const ChartContainer = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: '24px',
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: `
      0 0 5px rgba(220, 251, 1, 0.2),
      0 0 10px rgba(220, 251, 1, 0.2),
      0 0 15px rgba(220, 251, 1, 0.1)
    `,
  }
}));

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchStats = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await api.get('/admin/dashboard/stats');
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
      setError('Failed to load dashboard statistics');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!stats) return null;

  const statsCards: StatsCard[] = [
    {
      title: 'Pending Requests',
      value: stats?.pendingRequests || 0,
      icon: <AccessTime />,
      color: 'warning'
    },
    {
      title: 'Approved Requests',
      value: stats?.approvedRequests || 0,
      icon: <Receipt />,
      color: COLORS.secondary
    },
    {
      title: 'Total Users',
      value: stats?.totalUsers || 0,
      icon: <QueryStats />,
      color: COLORS.primary
    },
    {
      title: 'Total Teams',
      value: stats?.totalTeams || 0,
      icon: <AutoAwesome />,
      color: COLORS.secondary
    },
    {
      title: 'Monthly API Usage',
      value: (stats?.currentMonthUsage?.requests || 0).toLocaleString(),
      icon: <DataUsage />,
      color: COLORS.primary,
      unit: 'requests',
      subtitle: `$${stats?.currentMonthUsage?.spend} / ${(stats?.currentMonthUsage?.tokens || 0).toLocaleString()} tokens`
    },
    {
      title: 'Total API Usage',
      value: (stats?.totalUsage || 0).toLocaleString(),
      icon: <Speed />,
      color: COLORS.secondary,
      unit: 'requests'
    },
    {
      title: 'Weekly Usage ($)',
      value: `$${stats?.weeklyUsage?.spend || '0.00'}`,
      icon: <DataUsage />,
      color: COLORS.primary,
      subtitle: `${(stats?.weeklyUsage?.requests || 0).toLocaleString()} requests`
    },
    {
      title: 'Monthly Usage ($)',
      value: `$${stats?.monthlyUsage?.spend || '0.00'}`,
      icon: <Speed />,
      color: COLORS.secondary,
      subtitle: `${(stats?.monthlyUsage?.requests || 0).toLocaleString()} requests`
    }
  ];

  return (
    <Box p={3} sx={{ background: COLORS.background, minHeight: '100vh' }}>
      <Grid container spacing={3} mb={4}>
        {statsCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatCard {...card} />
          </Grid>
        ))}
      </Grid>

      {stats.recentActivity && stats.recentActivity.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <ChartContainer>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  fontWeight: 600,
                  mb: 3,
                  color: COLORS.primary
                }}
              >
                Recent Activity
              </Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart 
                    data={stats.recentActivity
                      .map(item => {
                        const date = new Date(item.date);
                        date.setDate(date.getDate() + 1);
                        return {
                          ...item,
                          date: date.toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit'
                          })
                        };
                      })
                      .sort((a, b) => {
                        const [dayA, monthA] = a.date.split('/');
                        const [dayB, monthB] = b.date.split('/');
                        const dateA = new Date(2024, parseInt(monthA) - 1, parseInt(dayA));
                        const dateB = new Date(2024, parseInt(monthB) - 1, parseInt(dayB));
                        return dateA.getTime() - dateB.getTime();
                      })}
                  >
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      stroke={`${COLORS.secondary}25`}
                    />
                    <XAxis 
                      dataKey="date" 
                      stroke="#ffffff"
                      tick={{ fill: '#ffffff' }}
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis 
                      stroke="#ffffff"
                      tick={{ fill: '#ffffff' }}
                    />
                    <Tooltip 
                      contentStyle={{
                        background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.98) 0%, rgba(18, 18, 18, 0.98) 100%)',
                        border: `1px solid ${COLORS.secondary}40`,
                        borderRadius: '12px',
                        color: COLORS.primary
                      }}
                      cursor={{ 
                        stroke: COLORS.primary,
                        strokeWidth: 1
                      }}
                      formatter={(value: number) => [value.toLocaleString(), 'Requests']}
                    />
                    <Line 
                      type="monotone"
                      dataKey="requests" 
                      stroke={COLORS.primary}
                      strokeWidth={2}
                      dot={{
                        fill: COLORS.primary,
                        stroke: COLORS.primary,
                        r: 4
                      }}
                      activeDot={{
                        fill: COLORS.primary,
                        stroke: COLORS.primary,
                        r: 6,
                        strokeWidth: 2
                      }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </ChartContainer>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard; 