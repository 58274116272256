import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Chip,
  Button,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import TeamAssignModal from '../TeamAssignModal/TeamAssignModal';
import api from '../../services/api';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  '& .MuiTableCell-root': {
    borderColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  borderRadius: '8px',
  fontWeight: 500,
  '&.pending': {
    backgroundColor: 'rgba(255, 171, 0, 0.1)',
    color: '#FFAB00',
  },
  '&.approved': {
    backgroundColor: 'rgba(220, 251, 1, 0.1)',
    color: '#DCFB01',
  },
  '&.rejected': {
    backgroundColor: 'rgba(121, 62, 238, 0.1)',
    color: '#793EEE',
  },
}));

interface AccessRequest {
  id: string;
  user_id: string;
  username: string;
  email: string;
  company: string;
  industry: string;
  status: 'pending' | 'approved' | 'rejected';
  created_at: string;
  updated_at: string;
}

interface AccessRequestsTableProps {
  onAssignTeam: (requestId: string) => void;
  refreshKey?: number;
  onTableUpdate?: () => void;
  status: 'pending' | 'rejected';
}

const AccessRequestsTable: React.FC<AccessRequestsTableProps> = ({ 
  onAssignTeam, 
  refreshKey = 0,
  onTableUpdate,
  status 
}) => {
  const [requests, setRequests] = useState<AccessRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedRequest, setSelectedRequest] = useState<AccessRequest | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const response = await api.get('/admin/access-requests');
      console.log('📥 Received access requests:', response.data);
      setRequests(response.data || []);
    } catch (error) {
      console.error('❌ Error fetching access requests:', error);
      setError('Failed to fetch access requests');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [refreshKey]);

  useEffect(() => {
    console.log('Current state:', {
      loading,
      error,
      requestsCount: requests.length,
      filteredCount: filteredRequests.length,
      status
    });
  }, [loading, error, requests, status]);

  const handleApprove = async (request: AccessRequest) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleReject = async (requestId: string) => {
    try {
      await api.post(`/admin/access-requests/${requestId}/reject`, {
        adminId: localStorage.getItem('userId')
      });
      fetchRequests();
      onTableUpdate?.();
    } catch (err) {
      console.error('Error rejecting request:', err);
      setError('Failed to reject request');
    }
  };

  const handleTeamAssign = async (requestId: string, teamId: string) => {
    setModalLoading(true);
    try {
      await api.post(`/admin/access-requests/${requestId}/approve`, {
        teamId,
        adminId: localStorage.getItem('userId')
      });
      
      console.log('✅ Team assigned and request approved');
      
      setIsModalOpen(false);
      await fetchRequests();
      onTableUpdate?.();
    } catch (err) {
      console.error('Error approving request:', err);
      setError('Failed to approve request');
    } finally {
      setModalLoading(false);
    }
  };

  const filteredRequests = requests.filter(request => request.status === status);

  if (loading) {
    return (
      <Box sx={{ 
        p: 4, 
        textAlign: 'center',
        background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.05)',
        borderRadius: '24px',
      }}>
        <Typography>Loading access requests...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (filteredRequests.length === 0) {
    return (
      <Box 
        sx={{ 
          p: 4, 
          textAlign: 'center',
          background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.05)',
          borderRadius: '24px',
        }}
      >
        <Typography variant="h6" color="text.secondary">
          {status === 'pending' 
            ? 'No pending access requests'
            : 'No rejected access requests'
          }
        </Typography>
      </Box>
    );
  }

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Industry</TableCell>
            <TableCell>Created At</TableCell>
            {status === 'pending' && <TableCell align="right">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRequests.map((request) => (
            <TableRow key={request.id}>
              <TableCell>{request.username}</TableCell>
              <TableCell>{request.email}</TableCell>
              <TableCell>{request.company}</TableCell>
              <TableCell>{request.industry}</TableCell>
              <TableCell>
                {new Date(request.created_at).toLocaleDateString()}
              </TableCell>
              {status === 'pending' && (
                <TableCell align="right">
                  <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => onAssignTeam(request.id)}
                      variant="contained"
                      size="small"
                      sx={{ 
                        bgcolor: '#DCFB01', 
                        color: '#000',
                        '&:hover': {
                          bgcolor: 'rgba(220, 251, 1, 0.8)',
                        }
                      }}
                    >
                      Assign Team
                    </Button>
                    <Button
                      onClick={() => handleReject(request.id)}
                      variant="contained"
                      color="error"
                      size="small"
                    >
                      Reject
                    </Button>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default AccessRequestsTable; 