import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Dashboard from '../pages/Dashboard/Dashboard';
import Products from '../pages/Products/Products';
import ProductDetails from '../pages/Products/ProductDetails/ProductDetails';
import Teams from '../pages/Teams/Teams';
import Users from '../pages/Users/Users';
import AccessRequests from '../pages/AccessRequests/AccessRequests';
import Statistics from '../pages/Statistics/Statistics';
import ManageAdmins from '../pages/ManageAdmins/ManageAdmins';
import InterfaceBuilder from '../pages/InterfaceBuilder/InterfaceBuilder';
import { useAdminAuth } from '../context/AdminAuthContext';
import { Navigate } from 'react-router-dom';

const AdminRoutes = () => {
  const { admin } = useAdminAuth();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/users" element={<Users />} />
        <Route path="/access-requests" element={<AccessRequests />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/interface-builder" element={<InterfaceBuilder />} />
        <Route
          path="/manage-admins"
          element={
            admin?.role === 'super_admin' ? (
              <ManageAdmins />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </Layout>
  );
};

export default AdminRoutes; 