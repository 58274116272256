import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Slider,
  Card,
  CardContent,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from '@mui/material';
import {
  Close as CloseIcon,
  PhoneAndroid as MobileIcon,
  Laptop as DesktopIcon,
  Tablet as TabletIcon,
  ExpandMore as ExpandMoreIcon,
  Upload as UploadIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InterfaceComponent } from '../../types/interface-components';
import { ComponentConfig } from '../../types/interface-components';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    maxWidth: '90vw',
    maxHeight: '90vh',
  }
}));

const PreviewContainer = styled(Box)<{ device: string }>(({ theme, device }) => ({
  margin: '0 auto',
  border: '1px solid rgba(220, 251, 1, 0.2)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  overflow: 'auto',
  transition: 'all 0.3s ease-in-out',
  ...(device === 'mobile' && {
    width: '375px',
    minHeight: '667px',
    maxHeight: '80vh',
  }),
  ...(device === 'tablet' && {
    width: '768px',
    minHeight: '1024px',
    maxHeight: '80vh',
  }),
  ...(device === 'desktop' && {
    width: '100%',
    minHeight: '600px',
    maxHeight: '80vh',
  }),
}));

const ComponentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '8px',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const DeviceButton = styled(IconButton)<{ active: boolean }>(({ theme, active }) => ({
  color: active ? '#DCFB01' : 'rgba(255, 255, 255, 0.5)',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    color: active ? '#DCFB01' : 'rgba(220, 251, 1, 0.8)',
  },
}));

interface InterfacePreviewProps {
  open: boolean;
  onClose: () => void;
  components: InterfaceComponent[];
  productName: string;
  renderComponent: (component: InterfaceComponent) => React.ReactNode;
}

const InterfacePreview: React.FC<InterfacePreviewProps> = ({
  open,
  onClose,
  components,
  productName,
  renderComponent
}) => {
  const [device, setDevice] = useState<'mobile' | 'tablet' | 'desktop'>('desktop');

  const renderPreviewComponent = (component: InterfaceComponent) => {
    const previewComponent: InterfaceComponent = {
      ...component,
      props: {
        ...component.props,
        disabled: true,
      }
    };

    return (
      <ComponentWrapper key={component.id}>
        {renderComponent(previewComponent)}
      </ComponentWrapper>
    );
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: '#DCFB01' }}>
            Preview: {productName}
          </Typography>
          <Box>
            <DeviceButton
              active={device === 'mobile'}
              onClick={() => setDevice('mobile')}
            >
              <MobileIcon />
            </DeviceButton>
            <DeviceButton
              active={device === 'tablet'}
              onClick={() => setDevice('tablet')}
            >
              <TabletIcon />
            </DeviceButton>
            <DeviceButton
              active={device === 'desktop'}
              onClick={() => setDevice('desktop')}
            >
              <DesktopIcon />
            </DeviceButton>
            <IconButton onClick={onClose} sx={{ color: '#DCFB01', ml: 2 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <PreviewContainer device={device}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2,
            minHeight: '100%',
          }}>
            {components.map(renderPreviewComponent)}
          </Box>
        </PreviewContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default InterfacePreview; 