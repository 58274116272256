import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Tooltip,
  LinearProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  TablePagination,
  InputAdornment,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import api from '../../services/api';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  borderRadius: '24px',
  '& .MuiTableCell-root': {
    borderColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1E1E1E',
    backgroundImage: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '24px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
  },
  '& .MuiDialogTitle-root': {
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
    background: 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)',
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
  },
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      borderRadius: '12px',
    },
    color: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(220, 251, 1, 0.3)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DCFB01',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px 14px 0',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: '16px',
    color: 'rgba(255, 255, 255, 0.7)'
  },
}));

interface Team {
  id: number;
  team_id: string;
  name: string;
  description: string;
  litellm_key: string | null;
  quota_limit: number;
  quota_used: number;
  models: string[];
  status: string;
  created_at: string;
  users_count: number;
  users: Array<{
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  }>;
}

interface TeamDetailsDialogProps {
  team: Team | null;
  open: boolean;
  onClose: () => void;
}

const TeamDetailsDialog: React.FC<TeamDetailsDialogProps> = ({ team, open, onClose }) => {
  const [teamDetails, setTeamDetails] = useState<Team | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTeamDetails = async () => {
      if (team && open) {
        setLoading(true);
        try {
          const response = await api.get(`/admin/teams/${team.team_id}`);
          setTeamDetails(response.data);
        } catch (error) {
          console.error('Error fetching team details:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTeamDetails();
  }, [team, open]);

  if (!team) return null;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ color: '#DCFB01', fontSize: '1.5rem', fontWeight: 500 }}>
        Team Details
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#DCFB01' }}>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="text.secondary">Name</Typography>
                <Typography>{teamDetails?.name || team.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text.secondary">Team ID</Typography>
                <Typography sx={{ fontFamily: 'monospace' }}>{team.team_id}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary">Description</Typography>
                <Typography>{teamDetails?.description || team.description}</Typography>
              </Grid>
            </Grid>

            <Typography variant="h6" sx={{ mt: 4, mb: 2, color: '#DCFB01' }}>
              LiteLLM Key
            </Typography>
            <TextField
              fullWidth
              value={teamDetails?.litellm_key || 'No key assigned'}
              InputProps={{
                readOnly: true,
                sx: { 
                  fontFamily: 'monospace',
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  borderRadius: '12px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(220, 251, 1, 0.2)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(220, 251, 1, 0.3)',
                  },
                }
              }}
            />

            <Typography variant="h6" sx={{ mt: 4, mb: 2, color: '#DCFB01' }}>
              Team Members ({teamDetails?.users?.length || 0})
            </Typography>
            <TableContainer sx={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
              borderRadius: '12px',
              border: '1px solid rgba(255, 255, 255, 0.05)'
            }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teamDetails?.users?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No members in this team
                      </TableCell>
                    </TableRow>
                  ) : (
                    teamDetails?.users?.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          sx={{ 
            color: '#DCFB01',
            '&:hover': {
              backgroundColor: 'rgba(220, 251, 1, 0.1)',
            }
          }}
        >
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

interface TeamSettingsDialogProps {
  team: Team | null;
  open: boolean;
  onClose: () => void;
  onSave: (teamId: string, settings: any) => Promise<void>;
}

const TeamSettingsDialog: React.FC<TeamSettingsDialogProps> = ({ team, open, onClose, onSave }) => {
  const [settings, setSettings] = useState({
    quota_limit: '',
    models: '',
  });

  useEffect(() => {
    if (team) {
      setSettings({
        quota_limit: team.quota_limit?.toString() || '',
        models: Array.isArray(team.models) ? team.models.join(', ') : team.models || '',
      });
    }
  }, [team]);

  const handleSave = async () => {
    if (!team) return;
    await onSave(team.team_id, settings);
    onClose();
  };

  if (!team) return null;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ color: '#DCFB01', fontSize: '1.5rem', fontWeight: 500 }}>
        Team Settings
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Quota Limit"
            type="number"
            value={settings.quota_limit}
            onChange={(e) => setSettings({ ...settings, quota_limit: e.target.value })}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                '& fieldset': {
                  borderColor: 'rgba(220, 251, 1, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(220, 251, 1, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DCFB01',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(220, 251, 1, 0.7)',
                '&.Mui-focused': {
                  color: '#DCFB01',
                },
              },
            }}
          />
          <TextField
            label="Models"
            value={settings.models}
            onChange={(e) => setSettings({ ...settings, models: e.target.value })}
            fullWidth
            helperText="Comma-separated list of models"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                '& fieldset': {
                  borderColor: 'rgba(220, 251, 1, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(220, 251, 1, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DCFB01',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(220, 251, 1, 0.7)',
                '&.Mui-focused': {
                  color: '#DCFB01',
                },
              },
              '& .MuiFormHelperText-root': {
                color: 'rgba(255, 255, 255, 0.5)',
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          sx={{ 
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSave}
          sx={{
            color: '#DCFB01',
            '&:hover': {
              backgroundColor: 'rgba(220, 251, 1, 0.1)',
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const Teams: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const rowsPerPage = 15;

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/teams', {
        params: {
          page,
          limit: rowsPerPage,
          search: searchTerm,
        }
      });
      setTeams(response.data.teams);
      setTotalCount(response.data.total);
    } catch (error) {
      console.error('Failed to fetch teams:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [page, searchTerm]);

  const handleViewDetails = async (team: Team) => {
    try {
      console.log('🔍 Fetching team details:', { teamId: team.team_id });
      
      setSelectedTeam(team);
      setDetailsOpen(true);
    } catch (error) {
      console.error('❌ Error loading team details:', error);
    }
  };

  const handleOpenSettings = async (team: Team) => {
    try {
      console.log('⚙️ Opening team settings:', { teamId: team.team_id });
      
      setSelectedTeam(team);
      setSettingsOpen(true);
    } catch (error) {
      console.error('❌ Error opening team settings:', error);
    }
  };

  const handleSaveSettings = async (teamId: string, settings: any) => {
    try {
      await api.put(`/admin/teams/${teamId}`, settings);
      fetchTeams();
      setSettingsOpen(false);
    } catch (error) {
      console.error('Failed to update team settings:', error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Teams
      </Typography>

      <SearchField
        fullWidth
        variant="outlined"
        placeholder="Search teams..."
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Team ID</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Models</TableCell>
              <TableCell>Quota</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {loading ? 'Loading...' : 'No teams found'}
                </TableCell>
              </TableRow>
            ) : (
              teams.map((team) => (
                <TableRow key={team.team_id}>
                  <TableCell>
                    <Typography variant="body1">{team.name}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {team.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                      {team.team_id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      icon={<GroupIcon />}
                      label={team.users_count}
                      size="small"
                      sx={{
                        backgroundColor: 'rgba(220, 251, 1, 0.1)',
                        color: '#DCFB01',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {team.models.map((model: string) => (
                      <Chip
                        key={`${team.team_id}-${model}`}
                        label={model}
                        size="small"
                        sx={{
                          m: 0.5,
                          backgroundColor: 'rgba(121, 62, 238, 0.1)',
                          color: '#793EEE',
                        }}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography>
                        {team.quota_used} / {team.quota_limit}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={(team.quota_used / team.quota_limit) * 100}
                        sx={{
                          width: 100,
                          height: 6,
                          borderRadius: 3,
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: '#DCFB01',
                          },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={team.status}
                      size="small"
                      sx={{
                        backgroundColor: 
                          team.status === 'active' ? 'rgba(220, 251, 1, 0.1)' :
                          team.status === 'suspended' ? 'rgba(255, 171, 0, 0.1)' :
                          'rgba(255, 86, 86, 0.1)',
                        color:
                          team.status === 'active' ? '#DCFB01' :
                          team.status === 'suspended' ? '#FFAB00' :
                          '#FF5656',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(team.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="View Details">
                      <IconButton 
                        onClick={() => handleViewDetails(team)}
                        sx={{ color: '#DCFB01' }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Settings">
                      <IconButton 
                        onClick={() => handleOpenSettings(team)}
                        sx={{ color: '#DCFB01' }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[15]}
      />

      <TeamDetailsDialog
        team={selectedTeam}
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
      />

      <TeamSettingsDialog
        team={selectedTeam}
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        onSave={handleSaveSettings}
      />
    </Box>
  );
};

export default Teams; 