import React, { useState, useRef, useEffect } from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Slider,
  Box,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormGroup,
  Switch,
  IconButton,
  Button,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardActions,
  Grid,
  Stack,
  Divider,
  InputAdornment,
  Chip,
  Link,
  Icon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { 
  ComponentConfig,
  TextInputConfig,
  NumberInputConfig,
  SelectConfig,
  RadioGroupConfig,
  SliderConfig,
  CheckboxConfig,
  TabsConfig,
  AccordionConfig,
  CardConfig,
  GridConfig,
  FlexContainerConfig,
  DividerConfig,
  SpacerConfig,
  DatePickerConfig,
  TimePickerConfig,
  FileUploadConfig,
  ImageUploadConfig,
  ChatInterfaceConfig,
  ChatMessage,
} from '../../types/interface-components';
import { SxProps, Theme } from '@mui/material/styles';
import { 
  DatePicker,
  TimePicker,
  LocalizationProvider 
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { CloudUpload as UploadIcon, Image as ImageIcon, Delete as DeleteIcon, Send as SendIcon, AttachFile as AttachFileIcon, Code as CodeIcon, EmojiEmotions as EmojiIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';

interface ComponentRendererProps {
  config: ComponentConfig;
  onChange?: (value: any) => void;
  preview?: boolean;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.01)',
    }
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  '&.Mui-disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.01)',
  }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#DCFB01',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(220, 251, 1, 0.16)',
    },
  },
  '& .MuiSlider-track': {
    backgroundColor: '#DCFB01',
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'rgba(220, 251, 1, 0.2)',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '8px !important',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderRadius: '8px',
    '&.Mui-expanded': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }
  }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '12px',
  border: '1px solid rgba(220, 251, 1, 0.1)',
}));

interface CommonProps {
  disabled: boolean;
  required?: boolean;
  error?: boolean;
  sx?: SxProps<Theme>;
}

const PreviewWrapper = styled(Box)(({ theme }) => ({
  '&.preview-mode': {
    '& .MuiInputBase-root': {
      pointerEvents: 'none',
      opacity: 0.7,
      '&::before': {
        borderBottomStyle: 'dashed',
      }
    },
    '& .MuiButton-root': {
      pointerEvents: 'none',
      opacity: 0.7,
    },
    '& .MuiSlider-root': {
      pointerEvents: 'none',
      opacity: 0.7,
    },
    '& .MuiCheckbox-root': {
      pointerEvents: 'none',
      opacity: 0.7,
    },
    '& .MuiRadio-root': {
      pointerEvents: 'none',
      opacity: 0.7,
    },
    '& .MuiSelect-root': {
      pointerEvents: 'none',
      opacity: 0.7,
    }
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '500px',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  border: '1px solid rgba(220, 251, 1, 0.1)',
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(220, 251, 1, 0.3)',
    borderRadius: '3px',
  },
}));

const MessageBubble = styled(Box)<{ isUser?: boolean }>(({ theme, isUser }) => ({
  maxWidth: '80%',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1.5),
  borderRadius: '12px',
  backgroundColor: isUser ? 'rgba(220, 251, 1, 0.1)' : 'rgba(255, 255, 255, 0.05)',
  color: isUser ? '#DCFB01' : '#fff',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  position: 'relative',
}));

const InputArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(220, 251, 1, 0.1)',
}));

interface ChatInterfaceProps {
  config: ChatInterfaceConfig;
  onChange?: (value: any) => void;
  preview?: boolean;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({
  config,
  onChange = () => {},
  preview = false,
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;

    const newMessage: ChatMessage = {
      id: Date.now().toString(),
      type: 'user',
      content: inputValue,
      timestamp: new Date().toISOString(),
      status: 'sent',
    };

    setMessages(prev => [...prev, newMessage]);
    setInputValue('');
    onChange?.(newMessage);
  };

  return (
    <ChatContainer>
      <MessagesContainer>
        {config.welcomeMessage && (
          <MessageBubble>
            <Typography>{config.welcomeMessage}</Typography>
          </MessageBubble>
        )}
        
        {messages.map((message) => (
          <MessageBubble key={message.id} isUser={message.type === 'user'}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
              <Typography variant="caption" color="rgba(255, 255, 255, 0.5)">
                {message.type === 'user' ? 'You' : config.botName || 'Bot'}
              </Typography>
              {config.showTimestamp && (
                <Typography variant="caption" color="rgba(255, 255, 255, 0.5)">
                  {new Date(message.timestamp).toLocaleTimeString()}
                </Typography>
              )}
            </Box>
            <Typography>{message.content}</Typography>
            {message.attachments?.map((attachment, index) => (
              <Box key={index} sx={{ mt: 1 }}>
                {attachment.type === 'image' ? (
                  <Box
                    component="img"
                    src={attachment.url}
                    sx={{ maxWidth: '100%', borderRadius: '8px' }}
                  />
                ) : attachment.type === 'code' ? (
                  <Box
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                      p: 1,
                      borderRadius: '4px',
                      fontFamily: 'monospace',
                    }}
                  >
                    <pre style={{ margin: 0 }}>{attachment.url}</pre>
                  </Box>
                ) : (
                  <Link
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#DCFB01' }}
                  >
                    {attachment.name || 'Download file'}
                  </Link>
                )}
              </Box>
            ))}
          </MessageBubble>
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>

      <InputArea>
        {config.features?.emoji && (
          <IconButton sx={{ color: '#DCFB01' }}>
            <EmojiIcon />
          </IconButton>
        )}
        
        {config.features?.fileAttachments && (
          <IconButton sx={{ color: '#DCFB01' }}>
            <AttachFileIcon />
          </IconButton>
        )}

        <StyledTextField
          fullWidth
          placeholder={config.placeholder || 'Type a message...'}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          disabled={preview}
          multiline
          maxRows={4}
          sx={{ flex: 1 }}
        />

        <IconButton
          onClick={handleSendMessage}
          disabled={!inputValue.trim() || preview}
          sx={{
            color: '#DCFB01',
            '&.Mui-disabled': {
              color: 'rgba(220, 251, 1, 0.3)',
            },
          }}
        >
          <SendIcon />
        </IconButton>
      </InputArea>
    </ChatContainer>
  );
};

export const ComponentRenderer: React.FC<ComponentRendererProps> = ({
  config,
  onChange,
  preview = false,
}) => {
  const [value, setValue] = useState<any>(() => {
    if ('defaultValue' in config) {
      return config.defaultValue;
    }
    switch (config.type) {
      case 'text-input':
      case 'number-input':
        return '';
      case 'checkbox':
        return false;
      case 'radio-group':
      case 'select':
        return '';
      case 'slider':
        return (config as SliderConfig).min || 0;
      default:
        return '';
    }
  });

  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const handleChange = (newValue: any) => {
    if (!preview) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);
    
    const fileConfig = config as FileUploadConfig;
    if (fileConfig.preview) {
      const newPreviews = selectedFiles.map(file => URL.createObjectURL(file));
      setPreviews(newPreviews);
    }

    handleChange(selectedFiles);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || [])
      .filter(file => file.type.startsWith('image/'));
    
    setImages(selectedFiles);
    const newPreviews = selectedFiles.map(file => URL.createObjectURL(file));
    setImagePreviews(newPreviews);
    handleChange(selectedFiles);
  };

  const commonProps: CommonProps = {
    disabled: preview,
    required: !preview && config.validation?.required,
    error: !preview && config.validation?.required && !value,
    sx: {
      width: '100%',
      ...(preview && {
        pointerEvents: 'none',
        opacity: 0.8,
        cursor: 'not-allowed',
      }),
      ...(config.styling && {
        height: config.styling.height,
        margin: config.styling.margin,
        padding: config.styling.padding,
        backgroundColor: config.styling.backgroundColor,
        color: config.styling.textColor,
        borderRadius: config.styling.borderRadius,
        borderColor: config.styling.borderColor,
        fontSize: config.styling.fontSize,
        fontWeight: config.styling.fontWeight,
      }),
    },
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [expandedSections, setExpandedSections] = useState<string[]>(
    (config.type === 'accordion' ? (config as AccordionConfig).defaultExpanded : []) || []
  );

  function renderComponent() {
    switch (config.type) {
      case 'text-input': {
        const textConfig = config as TextInputConfig;
        return (
          <FormControl fullWidth error={commonProps.error}>
            <StyledTextField
              {...commonProps}
              label={textConfig.label}
              placeholder={preview ? '' : textConfig.placeholder}
              value={preview ? textConfig.defaultValue || '' : value}
              onChange={(e) => handleChange(e.target.value)}
              multiline={textConfig.multiline}
              rows={textConfig.rows}
              InputProps={{
                readOnly: preview,
                startAdornment: textConfig.prefix && (
                  <InputAdornment position="start">{textConfig.prefix}</InputAdornment>
                ),
                endAdornment: textConfig.suffix && (
                  <InputAdornment position="end">{textConfig.suffix}</InputAdornment>
                ),
              }}
            />
            {commonProps.error && (
              <FormHelperText>{textConfig.validation?.errorMessage || 'This field is required'}</FormHelperText>
            )}
          </FormControl>
        );
      }

      case 'number-input': {
        const numberConfig = config as NumberInputConfig;
        return (
          <FormControl fullWidth error={commonProps.error}>
            <StyledTextField
              {...commonProps}
              type="number"
              label={numberConfig.label}
              placeholder={numberConfig.placeholder}
              value={value}
              onChange={(e) => handleChange(Number(e.target.value))}
              inputProps={{
                min: numberConfig.min,
                max: numberConfig.max,
                step: numberConfig.step,
              }}
            />
            {commonProps.error && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
        );
      }

      case 'checkbox': {
        const checkboxConfig = config as CheckboxConfig;
        return (
          <FormControlLabel
            control={
              <Checkbox
                {...commonProps}
                checked={!!value}
                onChange={(e) => handleChange(e.target.checked)}
                sx={{
                  color: '#DCFB01',
                  '&.Mui-checked': {
                    color: '#DCFB01',
                  },
                }}
              />
            }
            label={checkboxConfig.label}
          />
        );
      }

      case 'radio-group': {
        const radioConfig = config as RadioGroupConfig;
        return (
          <FormControl component="fieldset" error={commonProps.error}>
            {config.label && (
              <Typography gutterBottom>{config.label}</Typography>
            )}
            <RadioGroup
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              sx={{
                flexDirection: radioConfig.displayLayout === 'horizontal' ? 'row' : 'column',
                gap: 1,
              }}
            >
              {radioConfig.options?.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio 
                      {...commonProps}
                      sx={{
                        color: '#DCFB01',
                        '&.Mui-checked': {
                          color: '#DCFB01',
                        },
                      }}
                    />
                  }
                  label={option.label}
                  disabled={option.disabled || preview}
                />
              ))}
            </RadioGroup>
            {commonProps.error && (
              <FormHelperText>Please select an option</FormHelperText>
            )}
          </FormControl>
        );
      }

      case 'select': {
        const selectConfig = config as SelectConfig;
        return (
          <FormControl fullWidth error={commonProps.error}>
            <InputLabel>{selectConfig.label}</InputLabel>
            <StyledSelect
              {...commonProps}
              value={preview ? selectConfig.defaultValue || '' : value}
              onChange={(e) => handleChange(e.target.value)}
              input={<OutlinedInput label={selectConfig.label} />}
              multiple={selectConfig.multiple}
              displayEmpty={!!selectConfig.placeholder}
              renderValue={(selected) => {
                if (!selected) return selectConfig.placeholder;
                if (selectConfig.multiple) {
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={selectConfig.options.find(opt => opt.value === value)?.label}
                          size="small"
                        />
                      ))}
                    </Box>
                  );
                }
                return selectConfig.options.find(opt => opt.value === selected)?.label;
              }}
            >
              {selectConfig.placeholder && !selectConfig.multiple && (
                <MenuItem value="" disabled>
                  {selectConfig.placeholder}
                </MenuItem>
              )}
              {selectConfig.options?.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled || preview}
                >
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
            {commonProps.error && (
              <FormHelperText>{selectConfig.validation?.errorMessage || 'Please select an option'}</FormHelperText>
            )}
          </FormControl>
        );
      }

      case 'slider': {
        const sliderConfig = config as SliderConfig;
        return (
          <Box>
            {config.label && (
              <Typography gutterBottom>{config.label}</Typography>
            )}
            <StyledSlider
              {...commonProps}
              value={value}
              min={sliderConfig.min}
              max={sliderConfig.max}
              step={sliderConfig.step}
              marks={sliderConfig.marks}
              valueLabelDisplay={sliderConfig.valueLabelDisplay}
              onChange={(_, newValue) => handleChange(newValue)}
            />
          </Box>
        );
      }

      case 'tabs': {
        const tabsConfig = config as TabsConfig;
        return (
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={selectedTab}
              onChange={(_, newValue) => setSelectedTab(newValue)}
              orientation={tabsConfig.orientation || 'horizontal'}
              variant={tabsConfig.variant || 'standard'}
              centered={tabsConfig.centered}
              sx={{
                borderBottom: 1,
                borderColor: 'rgba(220, 251, 1, 0.2)',
                ...tabsConfig.styling?.tabStyle,
                '& .MuiTabs-indicator': {
                  backgroundColor: '#DCFB01',
                  ...tabsConfig.styling?.tabStyle?.indicator,
                },
              }}
            >
              {(tabsConfig.tabs || []).map((tab, index) => (
                <Tab
                  key={tab.id}
                  label={tab.label}
                  disabled={tab.disabled || preview}
                  icon={tab.icon && (
                    typeof tab.icon === 'string' ? (
                      <Icon>{tab.icon}</Icon>
                    ) : (
                      React.createElement(tab.icon as any)
                    )
                  )}
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-selected': {
                      color: '#DCFB01',
                    },
                  }}
                />
              ))}
            </Tabs>
            <Box sx={{ mt: 2, ...tabsConfig.styling?.panelStyle }}>
              {tabsConfig.tabs?.[selectedTab]?.content?.map((component) => (
                <Box key={component.id} sx={{ mb: 2 }}>
                  <ComponentRenderer
                    config={component.props}
                    onChange={onChange}
                    preview={preview}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        );
      }

      case 'accordion': {
        const accordionConfig = config as AccordionConfig;

        const handleAccordionChange = (sectionId: string) => {
          if (accordionConfig.allowMultiple) {
            setExpandedSections(prev =>
              prev.includes(sectionId)
                ? prev.filter(id => id !== sectionId)
                : [...prev, sectionId]
            );
          } else {
            setExpandedSections(prev =>
              prev.includes(sectionId) ? [] : [sectionId]
            );
          }
        };

        return (
          <Box>
            {(accordionConfig.sections || []).map((section) => (
              <Accordion
                key={section.id}
                expanded={expandedSections.includes(section.id)}
                onChange={() => handleAccordionChange(section.id)}
                disabled={section.disabled || preview}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  borderRadius: '12px !important',
                  mb: 1,
                  '&:before': {
                    display: 'none',
                  },
                  ...accordionConfig.styling,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: '#DCFB01' }} />}
                  sx={accordionConfig.styling?.headerStyle}
                >
                  <Box>
                    <Typography>{section.title}</Typography>
                    {section.subtitle && (
                      <Typography variant="body2" color="text.secondary">
                        {section.subtitle}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={accordionConfig.styling?.contentStyle}>
                  {(section.content || []).map((component) => (
                    <Box key={component.id} sx={{ mb: 2 }}>
                      <ComponentRenderer
                        config={component.props}
                        onChange={onChange}
                        preview={preview}
                      />
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        );
      }

      case 'grid': {
        const gridConfig = config as GridConfig;
        return (
          <Grid
            container
            spacing={gridConfig.spacing || 2}
            justifyContent={gridConfig.justifyContent}
            alignItems={gridConfig.alignItems}
            sx={{
              width: '100%',
              ...gridConfig.styling
            }}
          >
            {(gridConfig.items || []).map((item) => (
              <Grid
                key={item.id}
                item
                xs={item.gridProps?.xs}
                sm={item.gridProps?.sm}
                md={item.gridProps?.md}
                lg={item.gridProps?.lg}
                xl={item.gridProps?.xl}
              >
                <ComponentRenderer
                  config={item.content.props}
                  onChange={onChange}
                  preview={preview}
                />
              </Grid>
            ))}
          </Grid>
        );
      }

      case 'flex-container': {
        const flexConfig = config as FlexContainerConfig;
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: flexConfig.direction || 'row',
              flexWrap: flexConfig.wrap || 'nowrap',
              justifyContent: flexConfig.justifyContent || 'flex-start',
              alignItems: flexConfig.alignItems || 'stretch',
              gap: flexConfig.spacing || 2,
              ...flexConfig.styling,
            }}
          >
            {(flexConfig.items || []).map((item) => (
              <Box
                key={item.id}
                sx={{
                  flex: item.flex,
                  order: item.order,
                  alignSelf: item.alignSelf,
                }}
              >
                <ComponentRenderer
                  config={item.content.props}
                  onChange={onChange}
                  preview={preview}
                />
              </Box>
            ))}
          </Box>
        );
      }

      case 'divider': {
        const dividerConfig = config as DividerConfig;
        return (
          <Divider
            orientation={dividerConfig.orientation}
            variant={dividerConfig.variant}
            textAlign={dividerConfig.textAlign}
            sx={{
              borderColor: 'rgba(220, 251, 1, 0.2)',
              ...(dividerConfig.text && {
                '&::before, &::after': {
                  borderColor: 'rgba(220, 251, 1, 0.2)',
                },
              }),
              ...dividerConfig.styling,
            }}
          >
            {dividerConfig.text}
          </Divider>
        );
      }

      case 'spacer': {
        const spacerConfig = config as SpacerConfig;
        return (
          <Box
            sx={{
              height: spacerConfig.height,
              width: spacerConfig.width,
              flex: spacerConfig.flex,
              ...spacerConfig.styling,
            }}
          />
        );
      }

      case 'date-picker': {
        const dateConfig = config as DatePickerConfig;
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth error={commonProps.error}>
              <DatePicker
                label={dateConfig.label}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => handleChange(newValue?.toDate())}
                disabled={preview}
                minDate={dateConfig.minDate ? dayjs(dateConfig.minDate) : undefined}
                maxDate={dateConfig.maxDate ? dayjs(dateConfig.maxDate) : undefined}
                disablePast={dateConfig.disablePast}
                disableFuture={dateConfig.disableFuture}
                views={dateConfig.views}
                openTo={dateConfig.openTo}
                slotProps={{
                  textField: {
                    ...commonProps,
                    error: commonProps.error,
                    helperText: commonProps.error ? 'This field is required' : undefined,
                  }
                }}
              />
            </FormControl>
          </LocalizationProvider>
        );
      }

      case 'time-picker': {
        const timeConfig = config as TimePickerConfig;
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth error={commonProps.error}>
              <TimePicker
                label={timeConfig.label}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => handleChange(newValue?.toDate())}
                disabled={preview}
                ampm={timeConfig.ampm}
                minutesStep={timeConfig.step}
                slotProps={{
                  textField: {
                    ...commonProps,
                    error: commonProps.error,
                    helperText: commonProps.error ? 'This field is required' : undefined,
                  }
                }}
              />
            </FormControl>
          </LocalizationProvider>
        );
      }

      case 'file-upload': {
        const fileConfig = config as FileUploadConfig;
        return (
          <Box>
            <input
              accept={fileConfig.accept?.join(',')}
              style={{ display: 'none' }}
              id={`file-upload-${config.id}`}
              multiple={fileConfig.multiple}
              type="file"
              onChange={handleFileChange}
              disabled={preview}
            />
            <label htmlFor={`file-upload-${config.id}`}>
              <Box
                sx={{
                  border: '2px dashed rgba(220, 251, 1, 0.3)',
                  borderRadius: '12px',
                  p: 3,
                  textAlign: 'center',
                  cursor: preview ? 'not-allowed' : 'pointer',
                  '&:hover': {
                    backgroundColor: preview ? undefined : 'rgba(220, 251, 1, 0.05)',
                  },
                }}
              >
                <UploadIcon sx={{ fontSize: 48, color: '#DCFB01', mb: 2 }} />
                <Typography>
                  {fileConfig.dropzoneText || 'Click or drag files to upload'}
                </Typography>
                {fileConfig.maxSize && (
                  <Typography variant="caption" color="text.secondary">
                    Max size: {fileConfig.maxSize}MB
                  </Typography>
                )}
              </Box>
            </label>

            {files.length > 0 && (
              <Box sx={{ mt: 2 }}>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 1,
                      border: '1px solid rgba(220, 251, 1, 0.1)',
                      borderRadius: '8px',
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ flex: 1 }}>{file.name}</Typography>
                    {!preview && (
                      <IconButton
                        onClick={() => {
                          const newFiles = files.filter((_, i) => i !== index);
                          setFiles(newFiles);
                          handleChange(newFiles);
                        }}
                        sx={{ color: '#DCFB01' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </Box>
            )}

            {fileConfig.preview && previews.length > 0 && (
              <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {previews.map((preview, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={preview}
                    sx={{
                      width: 100,
                      height: 100,
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        );
      }

      case 'image-upload': {
        const imageConfig = config as ImageUploadConfig;
        return (
          <Box>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id={`image-upload-${config.id}`}
              multiple={imageConfig.multiple}
              type="file"
              onChange={handleImageChange}
              disabled={preview}
            />
            <label htmlFor={`image-upload-${config.id}`}>
              <Box
                sx={{
                  border: '2px dashed rgba(220, 251, 1, 0.3)',
                  borderRadius: '12px',
                  p: 3,
                  textAlign: 'center',
                  cursor: preview ? 'not-allowed' : 'pointer',
                  '&:hover': {
                    backgroundColor: preview ? undefined : 'rgba(220, 251, 1, 0.05)',
                  },
                }}
              >
                <ImageIcon sx={{ fontSize: 48, color: '#DCFB01', mb: 2 }} />
                <Typography>
                  {imageConfig.dropzoneText || 'Click or drag images to upload'}
                </Typography>
                {imageConfig.maxSize && (
                  <Typography variant="caption" color="text.secondary">
                    Max size: {imageConfig.maxSize}MB
                  </Typography>
                )}
              </Box>
            </label>

            {imagePreviews.length > 0 && (
              <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {imagePreviews.map((preview, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'relative',
                      width: 100,
                      height: 100,
                    }}
                  >
                    <Box
                      component="img"
                      src={preview}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '8px',
                      }}
                    />
                    {!preview && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          const newImages = images.filter((_, i) => i !== index);
                          const newPreviews = imagePreviews.filter((_, i) => i !== index);
                          setImages(newImages);
                          setImagePreviews(newPreviews);
                          handleChange(newImages);
                        }}
                        sx={{
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#DCFB01',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        );
      }

      case 'chat-interface':
        return (
          <ChatInterface
            config={config as ChatInterfaceConfig}
            onChange={onChange || (() => {})}
            preview={preview}
          />
        );

      default:
        return (
          <Box
            sx={{
              p: 2,
              border: '1px dashed rgba(220, 251, 1, 0.3)',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <Typography color="text.secondary">
              Component type "{config.type}" not implemented yet
            </Typography>
          </Box>
        );
    }
  }

  return (
    <PreviewWrapper className={preview ? 'preview-mode' : ''}>
      {renderComponent()}
    </PreviewWrapper>
  );
}; 