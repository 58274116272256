import {
  Call as CallIcon,
  Chat as ChatIcon,
  Bolt as BoltIcon,
  Analytics as AnalyticsIcon,
  Psychology as PsychologyIcon,
  Image as ImageIcon
} from '@mui/icons-material';

export const PRODUCT_ICONS = [
  { type: 'CallIcon', icon: CallIcon, label: 'Call Icon' },
  { type: 'ChatIcon', icon: ChatIcon, label: 'Chat Icon' },
  { type: 'BoltIcon', icon: BoltIcon, label: 'Bolt Icon' },
  { type: 'AnalyticsIcon', icon: AnalyticsIcon, label: 'Analytics Icon' },
  { type: 'PsychologyIcon', icon: PsychologyIcon, label: 'Psychology Icon' },
  { type: 'ImageIcon', icon: ImageIcon, label: 'Image Icon' }
]; 