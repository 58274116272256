import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Key as KeyIcon,
  QueryStats as StatsIcon,
  OpenInNew as OpenInNewIcon,
  Receipt as ReceiptIcon,
  Timeline as TimelineIcon,
  Api as ApiIcon,
  Apps as AppsIcon,
  Settings as SettingsIcon,
  Assessment as AssessmentIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Code as CodeIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { keyframes } from '@mui/system';
import { useAdminAuth } from '../../context/AdminAuthContext';

const drawerWidth = 240;

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useAdminAuth();

  const handleNavigation = (path: string) => {
    console.log('Navigating to:', path);
    navigate(path);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
    { text: 'Statistics', icon: <StatsIcon />, path: '/statistics' },
    { text: 'Products', icon: <AppsIcon />, path: '/products' },
    { text: 'Constructor', icon: <CodeIcon />, path: '/interface-builder' },
    { text: 'Users', icon: <PeopleIcon />, path: '/users' },
    { text: 'Teams', icon: <BusinessIcon />, path: '/teams' },
    { text: 'Access Requests', icon: <KeyIcon />, path: '/access-requests' },
    ...(admin?.role === 'super_admin' ? [
      { 
        text: 'Manage Admins', 
        icon: <AdminPanelSettingsIcon />, 
        path: '/manage-admins' 
      }
    ] : []),
  ];

  const externalLinks = [
    { 
      text: 'Lago Billing', 
      icon: <ReceiptIcon />, 
      path: 'https://lago.neuroplume.com/',
      external: true 
    },
    { 
      text: 'Grafana', 
      icon: <TimelineIcon />, 
      path: 'https://grafana.neuroplume.com',
      external: true 
    },
    { 
      text: 'LiteLLM', 
      icon: <ApiIcon />, 
      path: 'https://litellm.neuroplume.com/ui',
      external: true 
    },
  ];

  const gradient = keyframes`
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: -200% center;
    }
  `;

  const handleClick = (item: { path: string, external?: boolean }) => {
    if (item.external) {
      window.open(item.path, '_blank', 'noopener,noreferrer');
    } else {
      navigate(item.path);
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#1E1E1E',
          borderRight: '1px solid rgba(255, 255, 255, 0.05)',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            background: 'linear-gradient(90deg, #DCFB01 0%, #793EEE 50%, #DCFB01 100%)',
            backgroundSize: '200% auto',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            animation: `${gradient} 9s linear infinite`,
          }}
        >
          Admin Panel
        </Typography>
      </Box>
      
      <List sx={{ flex: 1 }}>
        {menuItems.map((item) => (
          <ListItemButton
            key={item.text}
            onClick={() => handleNavigation(item.path)}
            selected={location.pathname === item.path}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'rgba(220, 251, 1, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(220, 251, 1, 0.2)',
                },
              },
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.05)',
              },
            }}
          >
            <ListItemIcon sx={{ color: '#DCFB01' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>

      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.05)' }} />
      
      <List>
        {externalLinks.map((item) => (
          <ListItemButton
            key={item.text}
            onClick={() => handleClick(item)}
            sx={{
              height: '48px',
              mb: 0.5,
              mx: 1,
              borderRadius: '12px',
              '&:hover': {
                backgroundColor: 'rgba(220, 251, 1, 0.05)',
                '& .MuiListItemIcon-root': {
                  transform: 'scale(1.2)',
                  color: '#DCFB01',
                },
              },
              '& .MuiTouchRipple-root': {
                color: 'rgba(220, 251, 1, 0.2)',
              },
            }}
          >
            <ListItemIcon 
              sx={{ 
                color: '#DCFB01',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={item.text} 
              primaryTypographyProps={{
                fontWeight: 400,
              }}
            />
            <OpenInNewIcon 
              sx={{ 
                fontSize: 16, 
                ml: 1, 
                color: 'rgba(255, 255, 255, 0.5)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.2)',
                },
              }} 
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;