import api from './api';
import { Product, ProductUsage, ProductVisibility } from '../types/products';
import { MetricsTemplate } from '../types/metrics';

export const productsApi = {
  getAllProducts: (params: {
    page?: number;
    limit?: number;
    status?: string;
    type?: string;
    search?: string;
  }) => {
    return api.get('/admin/products', { params });
  },

  getProduct: (id: string) => {
    return api.get(`/admin/products/${id}`);
  },

  getProductUsage: (id: string) => {
    return api.get<ProductUsage>(`/admin/products/${id}/usage`);
  },

  createProduct: (product: Partial<Product>) => {
    return api.post('/admin/products', product);
  },

  updateProduct: (id: string, product: Partial<Product>) => {
    return api.put(`/admin/products/${id}`, product);
  },

  deleteProduct: (id: string) => {
    return api.delete(`/admin/products/${id}`);
  },

  getMetricsTemplates: (productType: string) => {
    return api.get<MetricsTemplate[]>('/admin/metrics/templates', {
      params: { productType }
    });
  },

  updateProductVisibility: (id: string, visibility: ProductVisibility) => {
    return api.put(`/admin/products/${id}/visibility`, { visibility });
  },

  updateProductTeamAccess: (id: string, teamIds: string[]) => {
    return api.put(`/admin/products/${id}/team-access`, { teamIds });
  },

  getProductTeams: (id: string) => {
    return api.get(`/admin/products/${id}/allowed-teams`);
  },

  getInterfaceConfig: (productId: string) => {
    return api.get(`/admin/products/${productId}/interface`);
  },

  updateInterfaceConfig: (productId: string, config: any) => {
    return api.put(`/admin/products/${productId}/interface`, config);
  },

  getInterfaceTemplates: (productType?: string) => {
    return api.get('/admin/interface-templates', {
      params: { productType }
    });
  },

  createInterfaceTemplate: (data: {
    name: string;
    description: string;
    productType: string;
    components: any[];
    layout?: any;
    theme?: any;
  }) => {
    return api.post('/admin/interface-templates', data);
  },

  deleteInterfaceTemplate: (templateId: string) => {
    return api.delete(`/admin/interface-templates/${templateId}`);
  },

  applyInterfaceTemplate: (templateId: string, productId: string) => {
    return api.post(`/admin/interface-templates/${templateId}/apply/${productId}`);
  },
}; 